import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/gitlab/gitlab.reducer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import TimeBarWithPrint from "../../../../components/Plots/TimeBar/TimeBar";
import {ResponsiveLine} from "@nivo/line";


let path = 'gitlab/sales';

const GitlabSales = () => {
    let data = useSelector(state => state.gitlab.data)
    let dispatch = useDispatch()

    useEffect(() => {

        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])


    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Gitlab - Sales
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
    </>
}

const Graphs = () => {
    let data = useSelector(state => state.gitlab.data[path])

    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <GraphComponent
            data={convert(data['all_counts'])}
            title={'Sales team - absolute counts'}
            xaxis={'# sales team [calendar year]'}
            // GraphComp={ResponsiveLine}
        />

        <TimeBarWithPrint
            data={convert(data['all_churn_percentage'])}
            title={'Sales team churn - percentage'}
            xaxis={'# sales team churn [calendar year]'}
        />

        <TimeBarWithPrint
            data={convert(data['all_churn_absolute'])}
            title={'Sales team churn - absolute counts'}
            xaxis={'# sales team churn [calendar year]'}
        />

        <GraphComponent
            data={convert(data['managers_counts'])}
            title={'Sales team managers - absolute counts'}
            xaxis={'# sales team managers [calendar year]'}
        />

        <TimeBarWithPrint
            data={convert(data['managers_churn_percentage'])}
            title={'Sales team managers churn - percentage'}
            xaxis={'# sales team managers churn [calendar year]'}
        />

        <TimeBarWithPrint
            data={convert(data['managers_churn_absolute'])}
            title={'Sales team managers churn - absolute counts'}
            xaxis={'# sales team managers churn [calendar year]'}
        />

    </Container>
}

export default GitlabSales