let fields = [
    'lovable', 'complete', 'viable', 'available', 'planned'
]
export let convertData = (data) => {
    return fields.map(category => ({
            id: category,
            data: categoryConvert(data, category)
        })
    )
}


let categoryConvert = (data, category) => {
    return data.map(row => ({
        x: new Date(row['date']),
        y: row[category] ? row[category] : 0
    }))
}


export let timeCutoff = (data, leftCutoff, rightCutoff) => {
    return data.map(item => ({
        id: item.id,
        data: cutoffSeries(item.data, leftCutoff, rightCutoff)
    }))
}


export let cutoffSeries = (series, leftCutoff, rightCutoff) => {
    return rightCutoffSeries(leftCutoffSeries(series, leftCutoff), rightCutoff)
}

export let leftCutoffSeries = (series, cutoff) => {
    let leftSide = series.filter(point => point.x < cutoff)
    let middle = series.filter(point => point.x === cutoff)
    let rightSide = series.filter(point => point.x > cutoff)
    if (middle.length > 0) {
        return middle.concat(rightSide)
    }
    if (leftSide.length > 0) {
        return Array(
            {x: cutoff, y: leftSide[leftSide.length - 1].y}
        ).concat(rightSide)
    }
    return rightSide
}


export let rightCutoffSeries = (series, cutoff) => {
    let leftSide = series.filter(point => point.x < cutoff)
    let middle = series.filter(point => point.x === cutoff)
    let rightSide = series.filter(point => point.x > cutoff)
    if (middle.length > 0) {
        return rightSide.concat(middle)
    }
    if (rightSide.length > 0) {
        return leftSide.concat(Array(
            {x: cutoff, y: rightSide[0].y}
        ))
    }
    return leftSide
}

