import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/dataApi.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";
import TimeLinePlot from "../../../../components/Plots/TimeLinePlot/TimeLinePlot";


let path = 'productivity/twitter';

const TwitterPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Productivity - Built With
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}

const Graphs = () => {
    let data = useSelector(state => state.dataApi.data[path])
    return <div>
        <Graph data={data} title={'Twitter mentions'}/>
    </div>
}

const Graph = ({data, title}) => {
    const convertedData = []

    Object.keys(data).forEach(key => {
        convertedData.push(
            {id: key, data: convert(data[key])},
        )
    })

    return <TimeLinePlot
        data={convertedData}
        title={title}
        xaxis={``}
        lineProps={{
            yFormat: " >-d",
            colors: {scheme: 'category10'},
            legends: []
        }}

    />
}

export default TwitterPage