import withDataFetchAndTitle from "../../components/DataLoadPage";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {filterNestedDictWithOffset} from "../../../common/data_helpers";


const MongoDBEngines = ({data}) => {
    return <div>
        <TimeLinePlotWithSettings
            data={data['db_engines']}
            defaultSmoothing={undefined}
            smoothingUnit={"months"}

        />
    </div>
}
export default withDataFetchAndTitle(MongoDBEngines, 'mongodb/db_engines', 'MongoDB - DB Engines')
