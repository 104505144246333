import React from 'react';
import {Box, ListItem, Typography} from '@mui/material';
import List from "@mui/material/List";

const Methodology = () => {
    return (
        <Box component="section" sx={{padding: 2}}>
            <Typography variant="h5" gutterBottom>
                Snowflake Github Stars Methodology
            </Typography>
            <Box component="section" sx={{marginLeft: 2}}>
                <Typography variant="h6" gutterBottom>
                    API calls and filters
                </Typography>
                <Typography paragraph>
                    Github API is used for discovering repos and getting information about their star history. Two types
                    of API calls are used for repo discovery: search and organisation repo enumeration.
                </Typography>
                <Typography paragraph sx={{fontWeight: 'bold'}}>
                    Search API
                </Typography>
                <Typography paragraph>
                    returns top 1000 results for any given search query. The search queries used in the discovery sort
                    repos by the number of stars, so that the repo with most stars is on the top. Most repos on Github
                    have zero stars which means that for most search results less than a hundred repos contribute to all
                    of the stars and the other repos can be disregarded.
                </Typography>
                <Typography paragraph>
                    A filter is applied to every repo returned by the search query. For some products a topic filter is
                    used, for others a name filter. This is necessary because search returns many irrelevant results.
                    For example, searching for “snowflake” returns repos related to Twitter Snowflake ID generator as
                    well as other irrelevant projects that share this name. For snowflake, a topic filter is used: any
                    repo returned by the search needs to have “snowfalkedb” listed among its topics.
                </Typography>
                <Typography paragraph sx={{fontWeight: 'bold'}}>
                    Organisation repo enumeration API (org)
                </Typography>
                <Typography paragraph>
                    returns all the repos created by a specific Github organisation. These organisations were identified
                    as containing a significantly high number of related repos for each product. For Snowflake it is two
                    organisations: snowflakedb and Snowflake-Labs.
                </Typography>
                <Typography paragraph>
                    Again, a filter is applied to each repo returned by the API to filter out irrelevant repos. Usually
                    it’s a simple repo name filter - for snowflake query any repo needs to contain “snowflake” in the
                    name. This is used to filter out irrelevant repos in each organisation. For example, databricks
                    organisation has some popular repos about learning spark or scala style guide, irrelevant to their
                    product.
                </Typography>
            </Box>
            <Box component="section" sx={{padding: 2}}>
                <Typography variant="h6" gutterBottom>
                    Parameters
                </Typography>
                <Typography paragraph>
                    List of queries and filters for each product:
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    snowflake
                </Typography>
                <List dense>
                    <ListItem>search: snowflake, topic filter: snowflakedb</ListItem>
                    <ListItem>search: snowflakedb, topic filter: snowflakedb</ListItem>
                    <ListItem>org: snowflakedb, repo name filter: snowflake</ListItem>
                    <ListItem>org: Snowflake-Labs, repo name filter: snowflake</ListItem>
                </List>

                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    bigquery
                </Typography>
                <List dense>
                    <ListItem>search: bigquery, topic filter: bigquery</ListItem>
                    <ListItem>org: GoogleCloudPlatform, repo name filter: bigquery</ListItem>
                    <ListItem>org: googleapis, repo name filter: bigquery</ListItem>
                </List>

                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    databricks
                </Typography>
                <List dense>
                    <ListItem>search: databricks, repo name filter: databricks</ListItem>
                    <ListItem>org: databricks, repo name filter: databricks</ListItem>
                </List>

                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    redshift
                </Typography>
                <List dense>
                    <ListItem>search: redshift, topic filter: redshift</ListItem>
                    <ListItem>org: awslabs, repo name filter: redshift</ListItem>
                </List>

                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    azure synapse
                </Typography>
                <List dense>
                    <ListItem>search: synapse, topic filter: synapse-analytics</ListItem>
                    <ListItem>org: Azure-Samples, repo name filter contains synapse, doesn’t contain
                        synapseml</ListItem>
                    <ListItem>org: microsoft, repo name filter contains synapse, doesn’t contain synapseml</ListItem>
                    <ListItem>org: solliancenet, repo name filter contains synapse, doesn’t contain synapseml</ListItem>
                </List>

                <Typography variant="subtitle1" gutterBottom sx={{fontWeight: 'bold'}}>
                    firebolt
                </Typography>
                <List dense>
                    <ListItem>search: firebolt, repo name filter firebolt</ListItem>
                    <ListItem>org: firebolt-db, repo name filter firebolt</ListItem>
                </List>
            </Box>
        </Box>
    );
};

export default Methodology;
