import React, {useEffect, useRef, useState} from "react";
import {ResponsiveLine} from "@nivo/line";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {exportComponentAsPNG} from 'react-component-export-image';
import Button from '@mui/material/Button';

let ForwardGraph = React.forwardRef((props, ref) => {
    let data = useSelector(state => state.flightDataSRSForward.graphData)
    let region = useSelector(state => state.flightDataSRSForward.region)

    const [localData, setLocalData] = useState([])

    useEffect(() => {
        setLocalData(data)
    }, [data])


    return <div ref={ref}
                style={{height: 420,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {`${region} SRS Forward Graphs`}
        </Typography>
        <ResponsiveLine
            data={localData}
            margin={{top: 15, right: 100, bottom: 75, left: 50}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto'
            }}
            axisLeft={{
                legend: 'cycle counts',
                legendOffset: 12,
            }}
            axisBottom={{
                format: '%y %b',
                legend: 'year month',
                legendOffset: -12,
            }}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={"x"}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 5,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .1)',
                                itemOpacity: 1,
                            }
                        }
                    ]
                }
            ]}
        />
    </div>
})


let GraphWithPrint = () => {
    const componentRef = useRef();
    return (
        <React.Fragment>
            <ForwardGraph ref={componentRef}/>
            {/*<button onClick={() => exportComponentAsJPEG(componentRef)}>*/}
            {/*    Export As JPEG*/}
            {/*</button>*/}
            {/*<button onClick={() => exportComponentAsPDF(componentRef)}>*/}
            {/*    Export As PDF*/}
            {/*</button>*/}
            <Button
                variant={"text"}
                style={{marginTop: 20}}
                onClick={() => exportComponentAsPNG(componentRef)}
            >
                Export As PNG
            </Button>
        </React.Fragment>
    );

}

export default GraphWithPrint