import React from "react";

const CustomSliceTooltip = (sort = true) => ({slice}) => {
    return (
        <div
            style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
            }}
        >
            <div>Date <strong>{slice.points[0].data.xFormatted}</strong></div>
            <hr/>
            <table>
                <tbody>
                {optionalSort(sort)(slice.points).map(point => (
                    <tr>
                        <td key={1}>
                                        <span style={{
                                            display: 'block',
                                            width: '12px',
                                            height: '12px',
                                            background: point.serieColor,
                                            marginRight: '7px'
                                        }}
                                        ></span>
                        </td>
                        <td key={2}>{point.serieId}</td>
                        <td key={3}>
                            <strong>{point.data.originalY ? point.data.originalYFormatted : point.data.yFormatted}</strong></td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

const optionalSort = (shouldSort) => (points) => {
    if (shouldSort) {
        return points.sort(
            (point1, point2) => point2.data.y - point1.data.y
        )
    } else {
        return points
    }
}

export default CustomSliceTooltip