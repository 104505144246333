import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";


let path = 'autodesk/g2_buyer_intent';

const BuyerIntentGraphs = ({data: {comparisons}}) => {

    return <>
        {
            Object.keys(comparisons).map(key => (

                <AreaPlot data={comparisons[key]} title={key} key={key} lineProps={{curve: 'linear'}}
                          defaultSmoothing={3} smoothingUnit={'months'}
                          defaultNormalize={true}
                />
            ))
        }
    </>
}


export default withDataFetchAndTitle(BuyerIntentGraphs, path, 'Autodesk - G2 - Buyer Intent')