import React, {useRef} from "react";
import {ResponsiveHeatMap} from '@nivo/heatmap'
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";

let CohortTablePlot = React.forwardRef(({data, yLabel = 'Percentage'}, ref) => {

    return <div ref={ref}
                style={{height: 420,}}>
        <ResponsiveHeatMap
            data={data}
            margin={{top: 70, right: 80, bottom: 20, left: 180}}
            // valueFormat=">-.2s"
            valueFormat={(value) => !value ? "" : Math.round(value).toString()}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -90,
                legend: '',
                legendOffset: 46
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                // legend: 'country',
                legendPosition: 'middle',
                legendOffset: 40
            }}
            axisRight={null}
            colors={{
                type: 'quantize',
                // type: 'diverging',
                scheme: 'red_yellow_blue',
                // scheme: 'red_blue',
                steps: 20,
                // minValue: 0,
                // maxValue: 100,
                // divergeAt: 50
            }}
            emptyColor="#555555"
            borderWidth={1}
            borderColor="#000000"
            enableLabels={true}
            // tooltip={NoTooltip}
            legends={[
                {
                    anchor: 'right',
                    translateX: 50,
                    translateY: 0,
                    length: 200,
                    thickness: 10,
                    direction: 'column',
                    tickPosition: 'after',
                    tickSize: 3,
                    tickSpacing: 4,
                    tickOverlap: false,
                    tickFormat: '>-.2s',
                    title: `${yLabel} →`,
                    titleAlign: 'start',
                    titleOffset: 4
                }
            ]}
            annotations={[]}
        />
    </div>
})

let NoTooltip = () => (<></>)

let GraphWithPrint = ({data, yLabel = 'Percentage'}) => {
    const componentRef = useRef();
    return (
        <React.Fragment>
            <CohortTablePlot ref={componentRef}
                             data={data}
                             yLabel={yLabel}
            />

            <Button
                variant={"text"}
                style={{marginTop: 0, marginBottom: 20}}
                onClick={() => exportComponentAsPNG(componentRef)}
            >
                Export As PNG
            </Button>
        </React.Fragment>
    );

}

export default GraphWithPrint