import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {roundNumber} from "../../../../common/data_helpers";


function CagrsTable({cagrsRows}) {

    return (
        <TableContainer component={Paper} sx={{marginBottom: '25px'}}>
            <Typography variant={'h6'}>CAGRs</Typography>
            <Table aria-label="custom table" size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Ticker</TableCell>
                        <TableCell align="right">Actual</TableCell>
                        <TableCell align="right">Equal Weights</TableCell>
                        <TableCell align="right">Holdings Model</TableCell>
                        <TableCell align="right">Calculation Start Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {cagrsRows.map((row, index) => (
                        <TableRow key={index}>
                            <TableCell component="th" scope="row">
                                {row.ticker}
                            </TableCell>
                            <TableCell align="right">{roundNumber(row['Actual'], 1)}%</TableCell>
                            <TableCell align="right">{roundNumber(row['Equal Weights'], 1)}%</TableCell>
                            <TableCell align="right">{roundNumber(row['Holdings Model'], 1)}%</TableCell>
                            <TableCell align="right">{row['calcStartDate']}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CagrsTable;
