import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data.reducer";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";


let path = 'flight_data/flight_radar';

const FlightRadarPage = () => {
    let data = useSelector(state => state.flightData.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - Flight Radar
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.flightData.data[path])

    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <GraphSection data={data} calculation={'Totals'}/>
        <GraphSection data={data} calculation={'YOY'}/>
        <GraphSection data={data} calculation={'YOY2'}/>
        <GraphSection data={data} calculation={'Percentage of 2019'}/>

    </Container>
}


let GraphSection = ({data, calculation}) =>
    <GraphComponent
        data={convert(data[calculation])}
        title={`Flight Radar - ${calculation}`}
        xaxis={`${calculation}`}
    />


export default FlightRadarPage
