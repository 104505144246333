import React from "react";
import withNonEmptyData from "../../../../components/HelperComponents/NonEmptyComponent";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";


const TimeLineViewer = ({data, params}) => {
    return <div>
        <SafeTimeLinePlotWithSettings data={data} title={params.title}
                                      otherLineProps={{curve: "linear"}}/>
    </div>
}

const SafeTimeLinePlotWithSettings = withNonEmptyData(TimeLinePlotWithSettings)


export default TimeLineViewer