import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data.reducer";
import GraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Delayed from "../../../../components/Delayed/Delayed";
import {DashedSolidLine} from "./GraphHelper";
import {schemeCategory10} from 'd3-scale-chromatic';

let path = 'flight_data/first_flight';


const FlightDataFirstFlightsPage = () => {
    let data = useSelector(state => state.flightData.data)
    let dispatch = useDispatch()

    useEffect(() => {

        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - First Flights
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.flightData.data[path])
    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>


        <GraphComponent
            title={"First flights 4 weeks rolling sum"}
            data={
                [
                    {id: "Boeing", data: convert(data['mdf']['Boeing'])},
                    {id: "Airbus", data: convert(data['mdf']['Airbus'])},
                ]
            }
        />

        <GraphComponent
            title={"First flights 4 weeks rolling sum yoy"}
            data={
                [
                    {id: "Boeing", data: convert(data['yoy']['Boeing'])},
                    {id: "Airbus", data: convert(data['yoy']['Airbus'])},
                ]
            }
        />

        <GraphComponent
            title={"First flights 4 weeks rolling sum percentage of 2019"}
            data={
                [
                    {id: "Boeing", data: convert(data['p19']['Boeing'])},
                    {id: "Airbus", data: convert(data['p19']['Airbus'])},
                ]
            }
        />

    </Container>
}


export default FlightDataFirstFlightsPage
