import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import {millionFormat, percFormat, thousandsFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";

const IBKRAppAnnieGraphs = ({data}) => {
    return <div>
        <AreaPlot data={data['ibkr_sites']['visits']} title={"Interactive brokers sites"}
                  lineProps={{
                      curve: 'cardinal',
                  }}
                  yFormatFun={thousandsFormat}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={false}

        />
        <AreaPlot data={data['competitors_sites']['visits']} title={"Competitors sites"}
                  lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={false}
                  yFormatFun={millionFormat}

        />
        <AreaPlot data={data['competitors_logins']['visits']} title={"Competitors logins"}
                  lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  yFormatFun={millionFormat}

                  defaultNormalize={false}
        />

    </div>
}


export default withDataFetchAndTitle(IBKRAppAnnieGraphs, 'ibkr/semrush', 'IBKR - Semrush')