export const prepare_data = (data1, data1_name, data2, data2_name) => {
    let keySet = new Set()
    Object.keys(data1).forEach(key => keySet.add(key))
    Object.keys(data2).forEach(key => keySet.add(key))

    let keys = Array.from(keySet)
    keys.sort()

    return keys.map(key => {
        let item = {name: key}
        if (key in data1) {
            item[data1_name] = data1[key]
        }
        if (key in data2) {
            item[data2_name] = data2[key]
        }
        return item
    })

}