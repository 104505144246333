import withDataFetchAndTitle from "../../components/DataLoadPage";
import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {billionFormat, millionFormat, percFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";


const LLMsSemrushGraphs = ({data}) => {
    return <>
        <TimeLinePlotWithSettings
            data={data['visits']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Visits`}
            yFormatFun={billionFormat}
            key={'visits'}
        />
        <TimeLinePlotWithSettings
            data={data['users']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Users`}
            yFormatFun={millionFormat}
            key={'users'}
        />
        <TimeLinePlotWithSettings
            data={data['time_on_site']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Time On Site [seconds]`}
            key={'time_on_site'}
        />

        <TimeLinePlotWithSettings
            data={data['visits_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Visits YoY`}
            yFormatFun={percFormat}
            key={'visits_yoy'}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            otherLineProps={{curve: 'linear'}}

        />
        <TimeLinePlotWithSettings
            data={data['users_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Users YoY`}
            yFormatFun={percFormat}
            key={'users_yoy'}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            otherLineProps={{curve: 'linear'}}

        />
        <TimeLinePlotWithSettings
            data={data['time_on_site_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`Time On Site YoY`}
            key={'time_on_site_yoy'}
            yFormatFun={percFormat}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            otherLineProps={{curve: 'linear'}}

        />


    </>
}

export default withDataFetchAndTitle(LLMsSemrushGraphs, 'llms/semrush', 'LLMs - Semrush')
