import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";


let path = 'shopify/builtwith';

const ShopifyBuiltWithPage = ({data: {data: {builtwith, builtwith_monthly, shopify_builtwith_ratio}}}) => {
    return <>
        <TimeLinePlotWithSettings data={builtwith['Entire Internet']} title={'Entire Internet'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={builtwith['Top 1m']} title={'Top 1m'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={builtwith['Top 100k']} title={'Top 100k'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={builtwith['Top 10k']} title={'Top 10k'} yFormat=" >-d"/>

        <TimeLinePlotWithSettings data={shopify_builtwith_ratio} title={'Shopify Plus/Shopify [%]'} yFormat=" >-d"
                                  otherLineProps={{curve: 'linear'}}/>

        <AreaPlot data={builtwith_monthly['Entire Internet']} title={"Entire Internet"} key={"hi"} lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={true}
        />
        <AreaPlot data={builtwith_monthly['Top 1m']} title={"Top 1m"} key={"hi"} lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={true}
        />

        <AreaPlot data={builtwith_monthly['Top 100k']} title={"Top 100k"} key={"hi"} lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={true}
        />

        <AreaPlot data={builtwith_monthly['Top 10k']} title={"Top 10k"} key={"hi"} lineProps={{curve: 'cardinal'}}
                  defaultSmoothing={3} smoothingUnit={'months'}
                  defaultNormalize={true}
        />
    </>
}


export default withDataFetchAndTitle(ShopifyBuiltWithPage, path, 'Shopify - Built With')
