import React from "react";
import withDataFetchAndTitle from "../../../components/DataLoadPage";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {noFormat} from "../../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import {findSmallestY} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import {roundNumber} from "../../../../common/data_helpers";

const ReviewsGraphs = ({data: {review_graph}}) => {
    return <div>
        <TimeLinePlotWithSettings data={review_graph['ratings']} title='Ratings'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  defaultMinY={roundNumber(findSmallestY(review_graph['ratings']), 2) - 0.01}
                                  yFormatFun={noFormat}
                                  colorScheme={"spectral"}

        />

        <TimeLinePlotWithSettings data={review_graph['counts']} title='Review Counts'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  colorScheme={"spectral"}
                                  yFormatFun={noFormat}/>

        <TimeLinePlotWithSettings data={review_graph['switched_from']} title='Switched from'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  colorScheme={"spectral"}
                                  yFormatFun={noFormat}/>

        <TimeLinePlotWithSettings data={review_graph['switched_from_perc']} title='Switched from / Reviews [percentage]'
                                  defaultSmoothing={6}
                                  smoothingUnit={"months"}
                                  colorScheme={"spectral"}
                                  yFormatFun={noFormat}/>

    </div>
}


export default withDataFetchAndTitle(ReviewsGraphs, 'productivity/g2', 'Productivity - G2')