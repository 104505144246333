
export let convertDistributionData = (data) => {
    let dates = Object.keys(data).sort()
    return dates.map(cohort => ({
        id: cohort,
        data: dates.map(date => ({
            x: date,
            y: data[cohort][date] ? data[cohort][date] : null
        }))
    }))

}