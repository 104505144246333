import * as React from "react";
import Paper from "@mui/material/Paper";
import BumpPlot from "../../../components/Plots/BumpPlot/BumpPlot";
import {bumpData} from "./data";


const ExperimentsPage = () => {
    return <Paper sx={
        {
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 500,
            marginBottom: '50px'
        }
    }>
        <BumpPlot data={bumpData}/>
    </Paper>
}

export default ExperimentsPage