import withDataFetchAndTitle from "../../components/DataLoadPage";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {filterNestedDictWithOffset} from "../../../common/data_helpers";


const DBEngines = ({data}) => {
    return <div>
        <TimeLinePlotWithSettings
            data={filterNestedDictWithOffset(data['db_engines'], 24)}
            defaultSmoothing={undefined}
            smoothingUnit={"months"}

        />
    </div>
}
export default withDataFetchAndTitle(DBEngines, 'snowflake/db_engines', 'SNOW - DB Engines')
