import withDataFetchAndTitle from "../../../components/DataLoadPage";
import ETFSummaryTable from "./SummaryTable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import React from "react";
import ETFDetailsGraph from "./ETFDetailsGraph";


const EtfSpy = ({data}) => {
    return <div>
        <SectionAccordion sectionData={data['MED']} sectionName={'MED'}/>
        <SectionAccordion sectionData={data['TECH']} sectionName={'TECH'}/>

        {/*{JSON.stringify(data)}*/}

    </div>
}

const SectionAccordion = ({sectionData, sectionName}) => {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{sectionName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <ETFSummaryTable data={sectionData['summary']} sectionName={sectionName}/>
            {sectionData['details'].map(detailsData => <ETFDetailsGraph etfDetails={detailsData}/>)}
        </AccordionDetails>
    </Accordion>
}


export default withDataFetchAndTitle(EtfSpy, 'etf/spy', 'ETFs vs SPY',
    false,
    {padding: '10px !important'}
)
