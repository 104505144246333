import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from "@mui/material/Typography";
import {Checkbox} from "@mui/material";

let AreaPlotSettings = ({settings, slideInRight}) => {
    return <div className={slideInRight ? 'slideInRight' : ''}>

        {settings['smoothingUnit'] !== undefined ?
            <Box display="flex">
                <TextField id="standard-basic" label={`Smoothing [${settings['smoothingUnit']}]`} variant="standard"
                           value={settings['smoothing'][0] ? settings['smoothing'][0] : ''}
                           type="number"
                           onChange={(event) => {
                               settings['smoothing'][1](event.target.value);
                           }}
                           InputLabelProps={{
                               shrink: settings['smoothing'][0] !== undefined, // This ensures the label shrinks when there is a value
                           }}
                />
                <Button
                    onClick={() => settings['smoothing'][1](undefined)}
                ><CancelIcon/></Button>
            </Box> : null
        }
        <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
                <Typography>Normalize</Typography>
            </Box>
            <Checkbox
                checked={settings['normalize'][0]}
                onChange={(e) => settings['normalize'][1](!settings['normalize'][0])}
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </Box>
    </div>
}

export default AreaPlotSettings