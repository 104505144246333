import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/dataApi.reducer";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import EqualWeightsGraphs from "./EqualWeightsGraphs";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Accordion from "@mui/material/Accordion";


let path = 'etf/equal_weights';

const ETFEqualWeightsPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                ETFs - Equal Weights
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <VisualSection data={data}/>}
        </Container>
    </>
}

const VisualSection = ({data}) => {
    return <>
        <SectionAccordion sectionData={data['sections'][0]} sectionName={'MED'}/>
        <SectionAccordion sectionData={data['sections'][1]} sectionName={'TECH'}/>


    </>
}

const SectionAccordion = ({sectionData, sectionName}) => {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{sectionName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <EqualWeightsGraphs data={sectionData}/>


        </AccordionDetails>
    </Accordion>
}


export default ETFEqualWeightsPage
