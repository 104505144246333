import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall} from "../api_helpers";

export const gitlabSlice = createSlice({
    name: 'gitlab_planning',
    initialState: {
        dates: [],
        status: 'idle',
        date1Status: 'idle',
        date2Status: 'idle',
        date1: "",
        date2: "",
        dateData1: [],
        dateData2: [],
    },
    reducers: {
        setDate1: (state, action) => {
            state.date1 = action.payload
        },
        setDate2: (state, action) => {
            state.date2 = action.payload
        },

    },
    extraReducers(builder) {
        builder.addCase(fetchDates.pending, (state, action) => {
            state.status = 'loading'
        })
            .addCase(fetchDates.fulfilled, (state, action) => {
                let dates = action.payload;
                dates.sort()
                dates.reverse()
                state.dates = dates
                state.date1 = dates[0]
                state.date2 = dates[12]
                state.status = 'succeeded'
            })
            .addCase(fetchDates.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDateData1.pending, (state, action) => {
                state.date1Status = 'loading'
            })
            .addCase(fetchDateData1.fulfilled, (state, action) => {
                let dates = action.payload;
                state.dateData1 = dates
                state.date1Status = 'succeeded'
            })
            .addCase(fetchDateData1.rejected, (state, action) => {
                state.date1Status = 'failed'
                state.error = action.error.message
            })
            .addCase(fetchDateData2.pending, (state, action) => {
                state.date2Status = 'loading'
            })
            .addCase(fetchDateData2.fulfilled, (state, action) => {
                let dates = action.payload;
                state.dateData2 = dates
                state.date2Status = 'succeeded'
            })
            .addCase(fetchDateData2.rejected, (state, action) => {
                state.date2Status = 'failed'
                state.error = action.error.message
            })
    }
})

export const fetchDates = createAsyncThunk('gitlab_planning/fetchCategoriesDates', async () => {
    return apiCall("gitlab/categories")()
})


export const fetchDateData1 = createAsyncThunk('gitlab_planning/fetchCategoriesDateData1',
    async (date) => {
        return apiCall(`gitlab/categories/${date}`)()
    })

export const fetchDateData2 = createAsyncThunk('gitlab_planning/fetchCategoriesDateData2',
    async (date) => {
        return apiCall(`gitlab/categories/${date}`)()
    })

// Action creators are generated for each case reducer function
export const {setDate1, setDate2} = gitlabSlice.actions

export default gitlabSlice.reducer

