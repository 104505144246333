import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import {getFilenameFromUrl} from "../../../common/data_helpers";
import {isNotEmpty} from "../../../common/js_helpers";
import withNonEmptyData from "../../../components/HelperComponents/NonEmptyComponent";
import {noFormat, percFormat, thousandsFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";


const AppAnnieDexcomStyleGraphs = (cumulativeGraphs = true, regions = ['US', 'WW'], marketShareGraphs = true) => ({
                                                                                                                      data: {
                                                                                                                          data,
                                                                                                                          meta
                                                                                                                      }
                                                                                                                  }) => {
    console.log(data)
    return <>
        {
            (regions.length > 1) ?
                regions.map(region => <AccordionRegionComponent data={data} region={region}
                                                                cumulativeGraphs={cumulativeGraphs}/>)
                : <AppAnnieExportStyleRegionGraphs data={data} region={regions[0]}/>
        }


        <div style={{height: '25px'}}></div>
        {meta.uploads.map(up => <div><a href={up.share_url} target={"_blank"}>{getFilenameFromUrl(up.share_url)}</a>
            <div></div>
        </div>)}

    </>
}


const AccordionRegionComponent = ({data, region, cumulativeGraphs}) => {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{region}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <AppAnnieExportStyleRegionGraphs data={data} region={region} cumulativeGraphs={cumulativeGraphs}/>
        </AccordionDetails>
    </Accordion>
}

const SafeTimeLinePlotWithSettings = withNonEmptyData(TimeLinePlotWithSettings)

const AppAnnieExportStyleRegionGraphs = ({data, region, cumulativeGraphs, marketShareGraphs}) => {
    return <div>
        <SafeTimeLinePlotWithSettings
            data={data['downloads'][region]['downloads']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads`}
            yFormatFun={thousandsFormat}/>

        <SafeTimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads YoY`}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}
            otherLineProps={{curve: 'linear'}}
        />


        <SafeTimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads MoM`}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}
            otherLineProps={{curve: 'linear'}}
        />

        {marketShareGraphs ? <AreaPlot
            data={data['downloads'][region]['downloads_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads Market Share`}
        /> : null}

        {cumulativeGraphs ? <TimeLinePlotWithSettings
            data={data['downloads'][region]['downloads_cumsum']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads Cumsum`}
        /> : null}
        {cumulativeGraphs ? <AreaPlot
            data={data['downloads'][region]['downloads_cs_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - downloads CS Market Share`}
        /> : null}
        <SafeTimeLinePlotWithSettings
            data={data['dau'][region]['dau']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU`}
            otherLineProps={{curve: 'linear'}}
            yFormatFun={thousandsFormat}
        />

        <SafeTimeLinePlotWithSettings
            data={data['dau'][region]['dau_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU YoY`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}/>

        <SafeTimeLinePlotWithSettings
            data={data['dau'][region]['dau_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU MoM`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}/>


        {marketShareGraphs ? <AreaPlot
            data={data['dau'][region]['dau_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - DAU Market Share`}
        /> : null}

        <SafeTimeLinePlotWithSettings
            data={data['mau'][region]['mau']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU`}
            yFormatFun={thousandsFormat}

        />
        <SafeTimeLinePlotWithSettings
            data={data['mau'][region]['mau_yoy']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU YoY`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}/>

        <SafeTimeLinePlotWithSettings
            data={data['mau'][region]['mau_mom']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU MoM`}
            otherLineProps={{curve: 'linear'}}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            yFormatFun={percFormat}/>

        {marketShareGraphs ? <AreaPlot
            data={data['mau'][region]['mau_market_share']}
            defaultSmoothing={3}
            smoothingUnit={"months"}
            title={`${region} - MAU Market Share`}
        /> : null}
    </div>
}

export default AppAnnieDexcomStyleGraphs
