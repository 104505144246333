export let getDatesList = (data) => {
    let dates = data.map(v => v['date'])
    dates.sort()
    dates.reverse()
    return dates
}


export let getInitialDatesSelection = (dates) => {
    // let selections = {}
    // for (const date of dates) {
    //     selections[date] = false
    // }
    // selections[dates[0]] = true
    return [dates[0]]
}