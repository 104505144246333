import React from "react"
import NumberOfCustomersGraph from "./NumberOfCustomersGraph";
import {YearGraph} from "./CommonGraphs";
import moment from "moment";
import {noFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";


const HGInsightsGraphsView = ({hgInsightsData, mainApp}) => {
    let colors = Object.keys(hgInsightsData['number_customers']['series']).length <= 6 ? {scheme: 'nivo'} : {scheme: 'category10'};

    return <>
        {hgInsightsData['reported_vs_hg'] ?
            <NumberOfCustomersGraph numberOfCustomers={hgInsightsData['reported_vs_hg']}
                                    title={`Number of reported ${mainApp} customers vs HG Insights`}
                                    colors={colors}
            />
            : null}
        <YearGraph series={hgInsightsData['number_customers']['series']} title={'Number of customers'}
                   colors={colors}/>
        <YearGraph series={hgInsightsData['number_adds']['series']} title={'Number of adds'} colors={colors}/>
        <YearGraph series={hgInsightsData['yoy']['series']} title={'YoY % Growth'} maxY={120} colors={colors}/>
        <YearGraph series={hgInsightsData['overlap']['series']} title={`Customer overlap [%] with ${mainApp}`}
                   colors={colors}/>
        <YearGraph series={hgInsightsData['calculated_adds']['series']}
                   title={`Cumulative [%] of ${mainApp} customers adding competitors`}
                   colors={colors}/>
        <YearGraph series={hgInsightsData['calculated_adds_rev']['series']}
                   title={`Cumulative [%] of competitor customers adding ${mainApp}`}
                   colors={colors}/>
        <YearGraph series={hgInsightsData['win_ratios']['series']}
                   title={'Marginal Overlap Win Ratio'}
                   maxY={15}
                   colors={colors}
        />
        <YearGraph series={hgInsightsData['win_ratios_quarterly']['series']}
                   title={'Marginal Overlap Win Ratio - Quarterly'}
                   format={v => moment(v).subtract(1, 'days').format("YY[Q]Q")}
                   maxY={15}
                   colors={colors}
        />
    </>
}


export default HGInsightsGraphsView