import React, {useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination} from '@mui/material';


const HoldingsTable = ({rowData}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper sx={{width: '100%', overflow: 'hidden'}}>
            <TableContainer >
            {/*<TableContainer sx={{maxHeight: 440}}>*/}
                <Table stickyHeader aria-label="custom pagination table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Company Name</TableCell>
                            <TableCell>Exchange:Ticker</TableCell>
                            <TableCell align="right">Equity Percentage</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                                ? rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                : rowData
                        ).map((row) => (
                            <TableRow key={row['Exchange:Ticker']}>
                                <TableCell component="th" scope="row">
                                    {row['Company Name']}
                                </TableCell>
                                <TableCell>
                                    {row['Exchange:Ticker']}
                                </TableCell>
                                <TableCell align="right">
                                    {row['Latest']}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100, {label: 'All', value: -1}]}
                component="div"
                count={rowData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default HoldingsTable;
