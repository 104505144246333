import React from "react";
import {SimpleDisplayTable} from "../table/SimpleDisplayTable";
import {
    getFilenameFromUrl,
    getNumberRounder,
    getUniqueKeysOfNestedDicts,
    roundNumber
} from "../../../common/data_helpers";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";


const IndeedComPage = ({indeedData: {meta, data: {ads_counts, ads_counts_yoy}}}) => {
    const shareUrl = meta['share_url']

    return <div>
        <a href={shareUrl} target={"_blank"}>{getFilenameFromUrl(shareUrl)}</a>
        <div style={{height: '25px'}}></div>

        <SimpleDisplayTable data={ads_counts}
                            timePeriods={getUniqueKeysOfNestedDicts(ads_counts).slice(-16)}
                            numberFormat={getNumberRounder(0)}
                            title={"Indeed.com job counts"}
        />

        <div style={{height: '50px'}}></div>

        <TimeLinePlotWithSettings data={ads_counts} title={'Indeed.com job counts'} yFormat=" >-d" defaultMaxY={15}/>

        <div style={{height: '50px'}}></div>

        <SimpleDisplayTable data={ads_counts_yoy}
                            timePeriods={getUniqueKeysOfNestedDicts(ads_counts_yoy).slice(-16)}
                            numberFormat={n => `${roundNumber(n, 0)}%`}
                            title={"Indeed.com job counts YoY [3 months trailing mean]"}
        />

        <div style={{height: '50px'}}></div>

        <TimeLinePlotWithSettings data={ads_counts_yoy} title={'Indeed.com job counts YoY [3 months trailing mean]'} yFormat=" >-d" defaultMaxY={150}/>

        <div style={{height: '50px'}}></div>
    </div>
}

export default IndeedComPage