import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall, apiUrl} from "./api_helpers";

export const textCruncherSlice = createSlice({
    name: 'textCruncher',
    initialState: {
        evaluationData: undefined,
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchEvaluationData.pending, (state, action) => {
                state.evaluationData = undefined
            })
            .addCase(fetchEvaluationData.fulfilled, (state, action) => {
                state.evaluationData = action.payload
            })
            .addCase(fetchEvaluationData.rejected, (state, action) => {
                state.error = action.error.message
                console.log(state.error)
            })
    }
})


export const fetchEvaluationData = createAsyncThunk('textCruncher/fetchEvaluationData',
    async ({evaluationID}) => {
        let linkResult = await apiCall(`text_cruncher/evaluation/${evaluationID}`)();
        if (linkResult.status === 'success') {
            console.log("Hi")
            let payloadResponse = await fetch(linkResult.url);
            let payload = await payloadResponse.json()
            console.log(payload)
            return {...linkResult, payload: payload}
        } else {
            console.log(linkResult)
            return linkResult
        }

    })


export default textCruncherSlice.reducer

