import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";


const BuiltwithGraphsPage = ({data}) => {

    return <div>
        <TimeLinePlotWithSettings data={data['Entire Internet']} title={'Entire Internet'} yFormat=" >-d"
                                  otherLineProps={{curve: 'linear'}}/>
        <TimeLinePlotWithSettings data={data['Top 1m']} title={'Top 1m'} yFormat=" >-d"
                                  otherLineProps={{curve: 'linear'}}/>
        <TimeLinePlotWithSettings data={data['Top 100k']} title={'Top 100k'} yFormat=" >-d"
                                  otherLineProps={{curve: 'linear'}}/>
        <TimeLinePlotWithSettings data={data['Top 10k']} title={'Top 10k'} yFormat=" >-d"
                                  otherLineProps={{curve: 'linear'}}/>
    </div>
}

export default BuiltwithGraphsPage

