import React from "react";
import IndeedComPage from "../../components/indeed_com/IndeedComPage";
import withDataFetchAndTitle from "../../components/DataLoadPage";


let path = 'productivity/indeed_v2';

const MondayIndeed = ({data}) => {

    return <>
        <IndeedComPage indeedData={data}/>
    </>
}


export default withDataFetchAndTitle(MondayIndeed, path, 'Productivity - Indeed.com job counts')