import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall} from "./api_helpers";

export const amznSlice = createSlice({
    name: 'amzn',
    initialState: {
        data: {},
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addCase(fetchData.pending, (state, action) => {
                state.data[action.meta.arg.path] = undefined
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data[action.meta.arg.path] = action.payload
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.error = action.error.message
                console.log(state.error)
            })
    }
})


export const fetchData = createAsyncThunk('amzn/fetchData', async ({path}) => {
    return apiCall(path)()
})


export default amznSlice.reducer

