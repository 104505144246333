import React from "react";
import withNonEmptyData from "../../../../components/HelperComponents/NonEmptyComponent";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";

const AppAnnieGraphPlot = ({graphData: {metric, data}, countryName}) => {
    switch (metric) {
        case "dl_monthly":
            return <SafeTimeLinePlotWithSettings
                data={data}
                defaultSmoothing={3}
                smoothingUnit={"months"}
                title={`${countryName} - monthly downloads`}
            />

        case "dl_daily":
            return <SafeTimeLinePlotWithSettings
                data={data}
                defaultSmoothing={30}
                smoothingUnit={"days"}
                title={`${countryName} - daily downloads`}
                otherLineProps={{animate: false}}
            />
        case "mau":
            return <SafeTimeLinePlotWithSettings
                data={data}
                defaultSmoothing={3}
                smoothingUnit={"months"}
                title={`${countryName} - monthly active users`}
                otherLineProps={{animate: false}}
            />
        // return <MultiLineGraphComponent
        //     data={Object.keys(data).map(key => ({
        //         id: key,
        //         data: convert(data[key])
        //     }))}
        //     title={`Daily downloads`}
        //     xaxis={``}
        // />
        default:
            return <div>

                {countryName} - {metric}
            </div>
    }
}

const SafeTimeLinePlotWithSettings = withNonEmptyData(TimeLinePlotWithSettings)

export default AppAnnieGraphPlot;