import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/amzn.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import TimeBarWithPrint from "../../../../components/Plots/TimeBar/TimeBar";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";


let path = 'amzn/sales';

const AmznCESalesPage = () => {
    let data = useSelector(state => state.amzn.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                AMZN - CE Weekly Sales
            </Typography>
        </Container>
        <Container>
            {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}

            {/*{data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}*/}
        </Container>
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.amzn.data[path])
    return <>
        <TimeBarWithPrint
            data={convert(data['weekly_sales'])}
            title={'Consumer Edge - Spend YoY growth [%]'}
            // xaxis={'# sales team churn [calendar year]'}
            timeFormat={'YY-MM-DD'}
            buttonStyle={{marginTop: '10px'}}
        />
    </>
}

export default AmznCESalesPage
