import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import {visuallyHidden} from '@mui/utils';
import {useEffect} from "react";


function descendingComparator(a, b, orderBy, def) {
    if ((b[orderBy] ?? def) < (a[orderBy] ?? def)) {
        return -1;
    }
    if ((b[orderBy] ?? def) > (a[orderBy] ?? def)) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy, -Infinity)
        : (a, b) => -descendingComparator(a, b, orderBy, Infinity);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    {
        id: 'zip_code',
        numeric: false,
        align: 'left',
        disablePadding: true,
        label: 'Zip Code',
    },
    {
        id: 'address',
        numeric: true,
        align: 'left',
        disablePadding: false,
        label: 'Address',
    },
    {
        id: 'price_processed',
        numeric: true,
        align: 'right',
        disablePadding: false,
        label: 'Price',
    },
    {
        id: 'year_built',
        numeric: true,
        align: 'right',
        disablePadding: false,
        label: 'Year Built',
    },
    {
        id: 'price_per_sf_processed',
        numeric: true,
        align: 'right',
        disablePadding: false,
        label: 'Price/SF',
    },
    {
        id: 'far',
        numeric: true,
        align: 'right',
        disablePadding: false,
        label: 'FAR',
    },
    {
        id: 'first_seen',
        numeric: false,
        align: 'right',
        disablePadding: false,
        label: 'First Seen',
    },
    {
        id: 'last_seen',
        numeric: false,
        align: 'right',
        disablePadding: false,
        label: 'Last Seen',
    },
    {
        id: 'status',
        numeric: true,
        align: 'right',
        disablePadding: false,
        label: 'AD Status',
    },
];

function EnhancedTableHead(props) {
    const {order, orderBy, rowCount, onRequestSort} =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};


export default function LoopnetViewTable({rows, latestDate}) {

    const [order, setOrder] = React.useState('desc');
    const [orderBy, setOrderBy] = React.useState('price_processed');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const visibleRows = React.useMemo(
        () =>
            stableSort(rows, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage, rows],
    );


    useEffect(() => {
        if (page * rowsPerPage >= rows.length) {
            let newPage = Math.max(Math.floor((rows.length - 1) / rowsPerPage), 0);
            setPage(newPage)
        }
    }, [rows, page, rowsPerPage]);


    return (
        <Box sx={{width: '100%'}}>
            <Paper sx={{width: '100%', mb: 2}}>
                {/*<EnhancedTableToolbar numSelected={selected.length}/>*/}
                <TableContainer>
                    <Table
                        sx={{minWidth: 750}}
                        aria-labelledby="tableTitle"
                        size={'small'}
                    >
                        <EnhancedTableHead
                            // numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {visibleRows.map((row, index) => {
                                const isItemSelected = false
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow
                                        hover
                                        // role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        // sx={{cursor: 'pointer'}}
                                    >
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                        >
                                            {row.zip_code}
                                        </TableCell>
                                        <TableCell>{row.address}</TableCell>
                                        <TableCell align="right">{row.price}</TableCell>
                                        <TableCell align="right">{row.year_built}</TableCell>
                                        <TableCell align="right">{row.price_per_sf}</TableCell>
                                        <TableCell align="right">{row.far}</TableCell>
                                        <TableCell align="right">{row.first_seen}</TableCell>
                                        <TableCell align="right">{row.last_seen}</TableCell>
                                        <TableCell align="right">
                                            <a target="_blank"
                                               href={`https://www.loopnet.com/Listing/${row.id}`}
                                            >{row.status}</a>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 33 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6}/>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    );
}