import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "@mui/material/Container";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DownloadIcon from '@mui/icons-material/Download';
import PreviewIcon from '@mui/icons-material/Preview';
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";


const ExportsPage = () => {
    function createData(name, date, uploader, format, preview_url, download_url) {
        return {name, date, uploader, format, preview_url, download_url};
    }

    const rows = [
        // createData('Rentals summary',
        //     "2022-11-14",
        //     "Oberndorf Robot",
        //     "xlsx",
        //     'https://docs.google.com/spreadsheets/d/1JzoxiIMD9r0Fs0y-gOfvQKc-X2E4yvrW/edit?usp=sharing&ouid=101260745262855641976&rtpof=true&sd=true',
        //     'https://etexuluthenisivenchunchlois.s3.amazonaws.com/82b4dc1e8c7bc3c53232900dd615d641/2022-11-14%20-%20Rentals%20Summary.xlsx'
        // ),
        createData('Bill.com semrush',
            "2022-12-17",
            "Oberndorf Robot",
            "xlsx",
            'https://docs.google.com/spreadsheets/d/1eKDcXRN-zk2x_jAjSD7KohR-BwMn9xo_/edit?usp=sharing&ouid=101260745262855641976&rtpof=true&sd=true',
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/9261e4e7d0fc6b813833c341886e820f/2022-12-17%20-%20Billdotcom%20Semrush.xlsx/2022-12-17%20-%20Billdotcom%20Semrush.xlsx'
        ),
        createData('Divvy Downloads',
            "2022-12-12",
            "Oberndorf Robot",
            "pdf",
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/d3911801db1da5dc393d95796ab19f35/2022-12-12%20-%20Divvy.pdf',
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/d3911801db1da5dc393d95796ab19f35/2022-12-12%20-%20Divvy.pdf'
        ),
        createData('Divvy Downloads',
            "2022-12-05",
            "Oberndorf Robot",
            "pdf",
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/94343ce55e24a8e6a1aeeddb5723f5ac/2022-12-05%20-%20Divvy.pdf',
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/94343ce55e24a8e6a1aeeddb5723f5ac/2022-12-05%20-%20Divvy.pdf'
        ),

        createData('Bill.com semrush',
            "2022-12-03",
            "Oberndorf Robot",
            "xlsx",
            'https://docs.google.com/spreadsheets/d/17I8R3KD5-Gbemozkmwcj9HzA-aonnQ2u/edit?usp=sharing&ouid=101260745262855641976&rtpof=true&sd=true',
            'https://etexuluthenisivenchunchlois.s3.amazonaws.com/281dfc75b0159d10059ac8421611ad19/2022-12-03%20-%20Billdotcom%20Semrush.xlsx/2022-12-03%20-%20Billdotcom%20Semrush.xlsx'
        ),


        // createData('Shopify Amazon Prime Statistics',
        //     "2022-11-10",
        //     "Prateek Modi",
        //     "xlsx",
        //     '',
        //     ''
        // ),
        // createData('Shopify Plus Analysis',
        //     "2022-11-09",
        //     "Dan Reuter",
        //     "pdf",
        //     '',
        //     ''
        // ),

    ];


    return <div>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4, justifyContent: "space-between", display: "flex"}}>
            {/*<Box sx={{flexGrow: 1}}>*/}
            {/*    <Grid container spacing={2}>*/}
            {/*        <Grid item xs={8}>*/}
            <Typography variant="h4" color="inherit" component="div">
                Exports
            </Typography>
            {/*</Grid>*/}
            {/*<Grid item xs={4}>*/}
            <Button
                // sx={{marginLeft: "auto"}}
                variant="contained">Upload
            </Button>
            {/*</Grid>*/}
            {/*</Grid>*/}
            {/*</Box>*/}
        </Container>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Export name</TableCell>
                            <TableCell align="right">Date</TableCell>
                            <TableCell align="right">Uploader</TableCell>
                            <TableCell align="right">Format</TableCell>
                            <TableCell align="right">Preview</TableCell>
                            <TableCell align="right">Download</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            >
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.date}</TableCell>
                                <TableCell align="right">{row.uploader}</TableCell>
                                <TableCell align="right">{row.format}</TableCell>
                                <TableCell align="right">
                                    <a href={row.preview_url} target='_blank'>
                                        <PreviewIcon/>
                                    </a>
                                </TableCell>
                                <TableCell align="right">
                                    <a href={row.download_url} download>
                                        <DownloadIcon/>
                                    </a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    </div>
}

export default ExportsPage
