import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import AcuityLinkedinPage from "../../components/acuity_linkedin/AcuityLinkedinPage";


let path = 'productivity/acuity_linkedin';

const MondayAcuityLinkedin = ({data}) => {

    return <>
        <AcuityLinkedinPage acuityLinkedinData={data}/>
    </>
}


export default withDataFetchAndTitle(MondayAcuityLinkedin, path, 'Productivity - Acuity Linkedin')