import React, {useEffect} from "react";
import RegionsSelector from "./regions_selector/RegionsSelector";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data_srs_forward.reducer";
import DateSelector from "./date_selector/DateSelector";
import ForwardGraph from "./forward_graph/ForwardGraph";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";


const SRSForwardGraphsPage = () => {
    const status = useSelector(state => state.flightDataSRSForward.status)
    const dispatch = useDispatch()

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchData())
        }
    }, [dispatch, status])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - SRS Forward Graphs
            </Typography>
        </Container>
        {status === 'succeeded' ?
            <DataComp/> :
            <LoadingSpinnerComponent/>
        }
    </>
}


const DataComp = () => {
    return <div>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 500,
                }
            }>
                <ForwardGraph/>
            </Paper>
        </Container>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>

            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'row',
                }
            }>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <DateSelector/>
                    </Grid>
                    <Grid item xs={6}>
                        <RegionsSelector/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    </div>
}

export default SRSForwardGraphsPage;