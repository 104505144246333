import {sortRegions} from "../SRSForwardGraphs/regions_selector/regions_sorter";

export let convertDistributionData = (data, regions) => {
    let dates = Object.keys(data).sort()
    return (regions ? regions : sortRegions([{data}])).map(region => ({
        id: region,
        data: dates.map(date => ({
            x: date,
            y: data[date][region]
        }))
    }))

}