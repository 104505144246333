import React from "react";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";
import withDataFetchAndTitle from "../../components/DataLoadPage";


let path = 'datadog/g2_buyer_intent';

const BuyerIntentGraphs = ({data: {comparisons}}) => {

    return <>
        {
            Object.keys(comparisons).map(key => (

                <AreaPlot data={comparisons[key]} title={key} key={key} lineProps={{curve: 'cardinal'}}
                          defaultSmoothing={3} smoothingUnit={'months'}
                          defaultNormalize={true}
                />
            ))
        }
    </>
}


export default withDataFetchAndTitle(BuyerIntentGraphs, path, 'Datadog - G2 - Buyer Intent')