import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography} from '@mui/material';
import {styled} from "@mui/material/styles";


const ETFSummaryTable = ({data, sectionName}) => {


    return (
        <TableContainer component={Paper}>
            <Typography variant="h5">{sectionName} ETF Summary Table</Typography>
            <Table size={'small'}>
                <TableHead>
                    <TableRow>
                        <TableCell>Ticker</TableCell>
                        <TableCell>ETF Name</TableCell>
                        <TableCell>Industry</TableCell>
                        <TableCell>Total Assets ($MM)</TableCell>
                        <TableCell align='right'>ETF CAGR </TableCell>
                        <TableCell align='right'>SPY CAGR </TableCell>
                        <TableCell align='right'>CAGR diff</TableCell>
                        <TableCell>Inception</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row) => (
                        <StyledTableRow key={row.Symbol}>
                            {/*<TableRow key={row.Symbol}>*/}
                            <TableCell>{row.Symbol}</TableCell>
                            <TableCell>{row["ETF Name"]}</TableCell>
                            <TableCell>{row.Industry}</TableCell>
                            <TableCell>{row["Total Assets($MM)"].toFixed(0)}</TableCell>
                            <TableCell align='right'>{row["ETF CAGR"].toFixed(1)}%</TableCell>
                            <TableCell align='right'>{row["SPY CAGR"].toFixed(1)}%</TableCell>
                            <TableCell align='right' sx={{color: row["CAGR diff"] > 0 ? 'green' : 'red'}}>
                                {row["CAGR diff"].toFixed(1)}%</TableCell>
                            <TableCell>{row.Inception}</TableCell>
                            {/*</TableRow>*/}
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));

export default ETFSummaryTable;
