import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../store/dataApi.reducer";


let path = 'etf/cagrs';

const ETFCAGRsPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                ETFs - CAGRs
            </Typography>
        </Container>
        <Container>
            {/*{data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}*/}
        </Container>
    </>
}


export default ETFCAGRsPage
