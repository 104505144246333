import React from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GraphComponent from "../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Accordion from "@mui/material/Accordion";

import MultiLineGraphComponent from "../../../components/Plots/MultiLinePlot/GraphComponent";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {extractSubDict} from "../../../common/data_helpers";

const WorkforcePageAlexStyle = ({data}) => (
    <>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_monthly')}
                                  title={'Linkedin Employee Counts'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_yoy')}
                                  title={'Linkedin Employee Counts YoY'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={extractSubDict(data, 'employee_counts_mom')}
                                  title={'Linkedin Employee Counts MoM'} yFormat=" >-d"/>
    </>
)



export default WorkforcePageAlexStyle
