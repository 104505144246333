import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Typography from "@mui/material/Typography";
import {ResponsiveLine} from "@nivo/line";
import {timeCutoff} from "./GraphData.helpers";
import CustomSliceTooltip from "../../../../../components/Plots/Tooltips/CustomSliceTooltip";

let GitlabPlanGraph = ({data, loading}) => {

    let leftCutoff = new Date(2018, 11, 31);
    let rightCutoff = new Date(2024, 1, 10);
    return <div
        style={{
            height: 420,
            filter: loading ? 'blur(4px)' : ''
            // backgroundColor: 'rgba(1,1,1,.5)'
        }}>

        <ResponsiveLine
            data={timeCutoff(data, leftCutoff, rightCutoff)}
            margin={{top: 15, right: 100, bottom: 75, left: 50}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
                // min: leftCutoff,
                // max: rightCutoff
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: true,
                min: 'auto'
            }}
            // axisLeft={{
            //     legend: 'cycle counts',
            //     legendOffset: 12,
            // }}
            axisBottom={{
                format: '%y %b',
                legend: 'year month',
                legendOffset: -12,
            }}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={"x"}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 5,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemBackground: 'rgba(0, 0, 0, .1)',
                                itemOpacity: 1,
                            }
                        }
                    ]
                }
            ]}
            sliceTooltip={CustomSliceTooltip(true)}
        />
    </div>
}


export default GitlabPlanGraph