import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/dataApi.reducer";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Accordion from "@mui/material/Accordion";

import MultiLineGraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";

let path = 'datadog/workforce';

const DatadogWorkforcePage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Datadog - Workforce
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <Graphs data={data}/>}
        </Container>
    </>
}


const Graphs = ({data}) => (
    <>

        <CompanyAccordion companyName={'Datadog'} companyData={data.datadog}/>
        <CompanyAccordion companyName={'Grafana'} companyData={data.grafana}/>
        <CompanyAccordion companyName={'Chronosphere'} companyData={data.chronosphere}/>
        <CompanyAccordion companyName={'New Relic'} companyData={data.new_relic}/>
        <CompanyAccordion companyName={'Splunk'} companyData={data.splunk}/>
        <CompanyAccordion companyName={'Dynatrace'} companyData={data.dynatrace}/>
        <CompanyAccordion companyName={'Logicmonitor'} companyData={data.logicmonitor}/>
        <CompanyAccordion companyName={'Cribl'} companyData={data.cribl}/>
        <CompanyAccordion companyName={'Coralogix'} companyData={data.coralogix}/>
        <AllAccordion data={data}/>
    </>
)

function CompanyAccordion({companyName, companyData}) {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{companyName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            {companyData.site_total_ads ?
                <GraphComponent
                    data={convert(companyData.site_total_ads)}
                    title={`${companyName} - number of job ads`}
                    xaxis={'# job ads'}
                /> : null}

            {companyData.site_sales_ads ?
                <GraphComponent
                    data={convert(companyData.site_sales_ads)}
                    title={`${companyName} - number of sales job ads`}
                    xaxis={'# sales job ads'}
                /> : null
            }
            <GraphComponent
                data={convert(companyData.linkedin_team_count)}
                title={`${companyName} - Linkedin number of employees`}
                xaxis={'# of employees (Linkedin)'}
            />
            <GraphComponent
                data={convert(companyData.linkedin_team_count_yoy)}
                title={`${companyName} - Linkedin number of employees YoY Growth`}
                xaxis={'# of employees (Linkedin)'}
            />
            <GraphComponent
                data={convert(companyData.linkedin_team_count_mom)}
                title={`${companyName} - Linkedin number of employees MoM Growth`}
                xaxis={'# of employees (Linkedin)'}
            />
            {companyData.site_team_count ?
                <GraphComponent
                    data={convert(companyData.site_team_count)}
                    title={`${companyName} - Website Number of employees`}
                    xaxis={'# of employees (Website)'}
                /> : null
            }
            {companyData.site_team_churn ?
                <GraphComponent
                    data={convert(companyData.site_team_churn)}
                    title={`${companyName} - Team Churn`}
                    xaxis={'# of employees churned'}
                /> : null
            }
            {companyData.site_team_churn_percentage ?
                <GraphComponent
                    data={convert(companyData.site_team_churn_percentage)}
                    title={`${companyName} - Team Churn Percentage`}
                    xaxis={'% of employees churned'}
                /> : null
            }

        </AccordionDetails>
    </Accordion>
}


function AllAccordion({data}) {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>All</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <MultiLineGraphComponent
                data={[
                    {id: "Datadog", data: convert(data['datadog']['site_total_ads_monthly'])},
                    {id: "Grafana", data: convert(data['grafana']['site_total_ads_monthly'])},
                    {id: "Chronosphere", data: convert(data['chronosphere']['site_total_ads_monthly'])},
                ]}
                title={`Number of job ads`}
                xaxis={``}
            />


            <MultiLineGraphComponent
                data={[
                    {id: "Datadog", data: convert(data['datadog']['site_sales_ads_monthly'])},
                    {id: "Grafana", data: convert(data['grafana']['site_sales_ads_monthly'])},
                    {id: "Chronosphere", data: convert(data['chronosphere']['site_sales_ads_monthly'])},
                ]}
                title={`Number of sales job ads`}

                xaxis={``}
            />

            <MultiLineGraphComponent
                data={[
                    {id: "Datadog", data: convert(data['datadog']['linkedin_team_count_monthly'])},
                    {id: "Grafana", data: convert(data['grafana']['linkedin_team_count_monthly'])},
                    {id: "Chronosphere", data: convert(data['chronosphere']['linkedin_team_count_monthly'])},
                    {id: "New Relic", data: convert(data['new_relic']['linkedin_team_count_monthly'])},
                    {id: "Splunk", data: convert(data['splunk']['linkedin_team_count_monthly'])},
                    {id: "Dynatrace", data: convert(data['dynatrace']['linkedin_team_count_monthly'])},
                    {id: "Logicmonitor", data: convert(data['logicmonitor']['linkedin_team_count_monthly'])},
                    {id: "Cribl", data: convert(data['cribl']['linkedin_team_count_monthly'])},
                    {id: "Coralogix", data: convert(data['coralogix']['linkedin_team_count_monthly'])},
                ]}
                title={`Linkedin number of employees`}

                xaxis={``}
            />

            <MultiLineGraphComponent
                data={[
                    {id: "Datadog", data: convert(data['datadog']['linkedin_team_count_yoy'])},
                    {id: "Grafana", data: convert(data['grafana']['linkedin_team_count_yoy'])},
                    {id: "Chronosphere", data: convert(data['chronosphere']['linkedin_team_count_yoy'])},
                    {id: "New Relic", data: convert(data['new_relic']['linkedin_team_count_yoy'])},
                    {id: "Splunk", data: convert(data['splunk']['linkedin_team_count_yoy'])},
                    {id: "Dynatrace", data: convert(data['dynatrace']['linkedin_team_count_yoy'])},
                    {id: "Logicmonitor", data: convert(data['logicmonitor']['linkedin_team_count_yoy'])},
                    {id: "Cribl", data: convert(data['cribl']['linkedin_team_count_yoy'])},
                    {id: "Coralogix", data: convert(data['coralogix']['linkedin_team_count_yoy'])},
                ]}
                title={`Linkedin number of employees YoY growth`}

                xaxis={``}
            />

            <MultiLineGraphComponent
                data={[
                    {id: "Datadog", data: convert(data['datadog']['linkedin_team_count_mom'])},
                    {id: "Grafana", data: convert(data['grafana']['linkedin_team_count_mom'])},
                    {id: "Chronosphere", data: convert(data['chronosphere']['linkedin_team_count_mom'])},
                    {id: "New Relic", data: convert(data['new_relic']['linkedin_team_count_mom'])},
                    {id: "Splunk", data: convert(data['splunk']['linkedin_team_count_mom'])},
                    {id: "Dynatrace", data: convert(data['dynatrace']['linkedin_team_count_mom'])},
                    {id: "Logicmonitor", data: convert(data['logicmonitor']['linkedin_team_count_mom'])},
                    {id: "Cribl", data: convert(data['cribl']['linkedin_team_count_mom'])},
                    {id: "Coralogix", data: convert(data['coralogix']['linkedin_team_count_mom'])},
                ]}
                title={`Linkedin number of employees MoM growth`}

                xaxis={``}
            />

        </AccordionDetails>
    </Accordion>
}


export default DatadogWorkforcePage
