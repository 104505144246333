import {configureStore} from "@reduxjs/toolkit";
import flightDataReducer from "./flight_data/flight_data_srs_forward.reducer";
import gitlabPlanningSliceReducer from "./gitlab/gitlabPlanning.reducer";
import gitlabReducer from "./gitlab/gitlab.reducer";
import {flightDataSlice} from "./flight_data/flight_data.reducer";
import billdotcomReducer from "./billdotocom.reducer";
import amznReducer from "./amzn.reducer";
import dataApiReducer from "./dataApi.reducer";
import textCruncherReducer from "./textCruncher.reducer";


export const store = configureStore({
    reducer: {
        flightDataSRSForward: flightDataReducer,
        flightData: flightDataSlice.reducer,
        gitlabPlanning: gitlabPlanningSliceReducer,
        gitlab: gitlabReducer,
        billdotcom: billdotcomReducer,
        amzn: amznReducer,
        dataApi: dataApiReducer,
        textCruncher: textCruncherReducer,
    }
})


