import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


const DateSelector = ({dates, currentDate, setDate}) => (
    <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Capture Date</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentDate}
            label="Capture Date"
            onChange={(change) => {
                setDate(change.target.value)
            }}
        >
            {
                dates.map(value => {
                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                })
            }
        </Select>
    </FormControl>
)


export default DateSelector