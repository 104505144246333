import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {sortRegions} from "../../pages/dashboard/flight_data/SRSForwardGraphs/regions_selector/regions_sorter";
import {
    getDatesList,
    getInitialDatesSelection
} from "../../pages/dashboard/flight_data/SRSForwardGraphs/date_selector/date_selector.helpers";
import {prepareGraphData} from "../../pages/dashboard/flight_data/SRSForwardGraphs/forward_graph/forwardGraph.helpers";
import {apiCall} from "../api_helpers";

export const flightDataSRSForwardSlice = createSlice({
    name: 'flight_data_srs_forward',
    initialState: {
        data: [],
        regions: [],
        region: "",
        status: 'idle',
        dates: [],
        dateSelections: [],
        graphData: [],
    },
    reducers: {
        setRegion: (state, action) => {
            state.region = action.payload
            state.graphData = prepareGraphData(state.data, state.region, state.dateSelections)
        },
        // flipDate: (state, action) => {
        //     state.dateSelections[action.payload] = !state.dateSelections[action.payload]
        //     state.graphData = prepareGraphData(state.data, state.region, state.dates, state.dateSelections)
        // },
        updateSelections: (state, action) => {
            let dateSelections = action.payload
            dateSelections.sort()
            state.dateSelections = dateSelections
            state.graphData = prepareGraphData(state.data, state.region, state.dateSelections)
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchData.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.status = 'succeeded'
                let data = action.payload;
                state.data = data
                let regions = sortRegions(data);
                state.regions = regions
                state.region = regions[0]
                state.dates = getDatesList(data)
                state.dateSelections = getInitialDatesSelection(state.dates)
                state.graphData = prepareGraphData(state.data, state.region, state.dateSelections)
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

export const fetchData = createAsyncThunk('flight_data_srs_forward/fetchData', async () => {
    return apiCall('srs_forward_graphs')()
})

// Action creators are generated for each case reducer function
export const {setRegion, updateSelections} = flightDataSRSForwardSlice.actions

export default flightDataSRSForwardSlice.reducer

