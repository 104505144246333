import React from "react";
import withEvaluationDataFetchAndTitle from "./FileLoadHOC";
import ClustersViewTable from "./ClustersViewTable";
import PointsViewTable from "./PointsView";


const TextCruncherEvaluation = ({data}) => {
    const [selected, setSelected] = React.useState(-1);

    return <>
        <ClustersView clustersData={data.payload.data.principal.cluster_info}
                      selected={selected} setSelected={setSelected}
        />
        <PointsViewTable points={data.payload.data.principal.points.filter(point => (point.cluster === selected))}/>
    </>
}


const ClustersView = ({clustersData, selected, setSelected}) => {
    return <>
        <ClustersViewTable rows={clustersData.map((cl) => ({...cl, quality: 1 - cl.mean}))}
                           selected={selected}
                           setSelected={setSelected}
        />
    </>
}


export default withEvaluationDataFetchAndTitle(TextCruncherEvaluation, 'procore_v0', "Text Cruncher Procore")