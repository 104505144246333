import React from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import {percFormat, thousandsFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";

const IBKRAppAnnieGraphs = ({data}) => {
    console.log(data)
    return <div>
        <CountryPlotGraphs countryData={data['ww']} accordionTitle={'Worldwide'}/>
        <CountryPlotGraphs countryData={data['us']} accordionTitle={'US'}/>
    </div>
}

const CountryPlotGraphs = ({countryData, accordionTitle}) => {
    console.log(countryData)
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{accordionTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <TimeLinePlotWithSettings data={countryData.monthly_downloads} title='Monthly Downloads [k]'
                                      defaultSmoothing={3}
                                      smoothingUnit={"months"}
            />
            <TimeLinePlotWithSettings data={countryData.monthly_downloads_yoy} title='Monthly Downloads YoY'
                                      defaultSmoothing={3}
                                      smoothingUnit={"months"}
                                      defaultMaxY={150}
                                      defaultMinY={-20}
                                      defaultOrderOfMagnitude={["[%]", 1]}

                                      yFormatFun={percFormat}/>

            <TimeLinePlotWithSettings data={countryData.mau} title='Monthly Visitors [millions]'
                                      defaultSmoothing={3}
                                      smoothingUnit={"months"}
            />


            <TimeLinePlotWithSettings data={countryData.mau_yoy} title='Monthly Visitors YoY'
                                      defaultSmoothing={3}
                                      smoothingUnit={"months"}
                                      defaultMaxY={150}
                                      defaultMinY={-20}
                                      defaultOrderOfMagnitude={["[%]", 1]}

                                      yFormatFun={percFormat}/>


        </AccordionDetails>
    </Accordion>
}

export default withDataFetchAndTitle(IBKRAppAnnieGraphs, 'ibkr/app_annie', 'IBKR - App Annie')