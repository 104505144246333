export let prepareGraphData = (data, region, selectedDates) => {
    let out = []
    for (const date of selectedDates) {
        out.push(prepareLine(data, region, date))
    }
    return out
}

let prepareLine = (data, region, date) => {
    let dataItem = extractDateFromData(data, date)
    let months = Object.keys(dataItem)
    months.sort()

    let lineData = []

    for (const month of months) {
        lineData.push({
            x: `${month}-01`,
            y: dataItem[month][region]
        })
    }
    return {
        id: date,
        data: lineData
    }

}


let extractDateFromData = (data, date) => {
    for (const item of data) {
        if (item['date'] === date) {
            return item['data']
        }
    }
}