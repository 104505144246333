import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/billdotocom.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {ResponsiveLine} from "@nivo/line";


let path = 'billdotcom/semrush';

const BillSemrushPage = () => {
    let data = useSelector(state => state.billdotcom.data)
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Bill.com - Semrush
            </Typography>
        </Container>
        <Container>
            {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}


const Graphs = () => {
    let {data} = useSelector(state => state.billdotcom.data[path])

    return <div>
        <CompetitorsBlock data={data}/>
        <SiteBlock site={'Bill.com'} data={data}/>
        <SiteBlock site={'Meliopayments.com'} data={data}/>
        <SiteBlock site={'Tipalti.com'} data={data}/>
        <SiteBlock site={'Stampli.com'} data={data}/>
        <SiteBlock site={'Bill.com + Melio Payments + Tipalti + Stampli'} data={data}/>
    </div>

}

const CompetitorsBlock = ({data}) => <Accordion TransitionProps={{unmountOnExit: true}}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
    >
        <Typography>Bill.com vs competitors</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <GraphComponent
            data={[
                {id: "Stampli.com", data: convert(data['Stampli.com']['Unique Visitors']["All Devices"])},
                {id: "Tipalti.com", data: convert(data['Tipalti.com']['Unique Visitors']["All Devices"])},
                {id: "Meliopayments.com", data: convert(data['Meliopayments.com']['Unique Visitors']["All Devices"])},
                {id: "Bill.com", data: convert(data['Bill.com']['Unique Visitors']["All Devices"])},
            ]}
            title={`Bill.com vs comp. Unique visitors - All Devices`}
            xaxis={``}
        />

        <GraphComponent
            data={[
                {id: "Stampli.com", data: convert(data['Stampli.com']['Intuit']["Traffic Share"])},
                {id: "Tipalti.com", data: convert(data['Tipalti.com']['Intuit']["Traffic Share"])},
                {id: "Meliopayments.com", data: convert(data['Meliopayments.com']['Intuit']["Traffic Share"])},
                {id: "Bill.com", data: convert(data['Bill.com']['Intuit']["Traffic Share"])},
            ]}
            title={`Intuit Traffic Share`}
            xaxis={``}
            stacked={true}
            csv={true}
            // GraphComp={ResponsiveLine}
        />

        <GraphComponent
            data={[
                {id: "Stampli.com", data: convert(data['Stampli.com']['Intuit']["Traffic"])},
                {id: "Tipalti.com", data: convert(data['Tipalti.com']['Intuit']["Traffic"])},
                {id: "Meliopayments.com", data: convert(data['Meliopayments.com']['Intuit']["Traffic"])},
                {id: "Bill.com", data: convert(data['Bill.com']['Intuit']["Traffic"])},
            ]}
            title={`Intuit Traffic`}
            xaxis={``}
        />

        <GraphComponent
            data={[
                {
                    id: "Stampli.com",
                    data: convert(data['Stampli.com']['Average Visit Duration (seconds)']["All Devices"])
                },
                {
                    id: "Tipalti.com",
                    data: convert(data['Tipalti.com']['Average Visit Duration (seconds)']["All Devices"])
                },
                {
                    id: "Meliopayments.com",
                    data: convert(data['Meliopayments.com']['Average Visit Duration (seconds)']["All Devices"])
                },
                {id: "Bill.com", data: convert(data['Bill.com']['Average Visit Duration (seconds)']["All Devices"])},
            ]}
            title={`Bill.com vs comp. Average Visit Duration (seconds) - All Devices`}
            xaxis={``}
        />


    </AccordionDetails>
</Accordion>

const SiteBlock = ({site, data}) => <Accordion TransitionProps={{unmountOnExit: true}}>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
    >
        <Typography>{site}</Typography>
    </AccordionSummary>
    <AccordionDetails>
        <MetricBlock data={data} metric='Visits' site={site}/>
        <MetricBlock data={data} metric='Unique Visitors' site={site}/>
        <MetricBlock data={data} metric='Average Visit Duration (seconds)'
                     absolute={false}
                     site={site}/>
        <TrafficSourcesBlock data={data} site={site}/>
    </AccordionDetails>
</Accordion>

const MetricBlock = ({site, data, metric, absolute = true}) => <>
    <GraphComponent
        data={[
            {id: "Mobile", data: convert(data[site][metric]["Mobile"])},
            {id: "Desktop", data: convert(data[site][metric]["Desktop"])},
            {id: "All Devices", data: convert(data[site][metric]["All Devices"])},
        ]}
        title={`${site} ${metric}`}
        xaxis={``}
    />

    <GraphComponent
        data={[
            {id: "Mobile", data: convert(data[site][`${metric}-yoy`]["Mobile"])},
            {id: "Desktop", data: convert(data[site][`${metric}-yoy`]["Desktop"])},
            {id: "All Devices", data: convert(data[site][`${metric}-yoy`]["All Devices"])},
        ]}
        title={`${site} ${metric} YoY %`}
        xaxis={``}
        axisBottomParams={{
            tickValues: 'every year',
        }}
    />

    {absolute ?
        <GraphComponent
            data={[
                {id: "Mobile", data: convert(data[site][`${metric}-yoya`]["Mobile"])},
                {id: "Desktop", data: convert(data[site][`${metric}-yoya`]["Desktop"])},
                {id: "All Devices", data: convert(data[site][`${metric}-yoya`]["All Devices"])},
            ]}
            title={`${site} ${metric} YoY #`}
            xaxis={``}
            axisBottomParams={{
                tickValues: 'every year',
            }}
        />
        : null}


    <GraphComponent
        data={[
            {id: "Mobile", data: convert(data[site][`${metric}-mom`]["Mobile"])},
            {id: "Desktop", data: convert(data[site][`${metric}-mom`]["Desktop"])},
            {id: "All Devices", data: convert(data[site][`${metric}-mom`]["All Devices"])},
        ]}
        title={`${site} ${metric} MoM %`}
        xaxis={``}
    />

    {absolute ?
        <GraphComponent
            data={[
                {id: "Mobile", data: convert(data[site][`${metric}-moma`]["Mobile"])},
                {id: "Desktop", data: convert(data[site][`${metric}-moma`]["Desktop"])},
                {id: "All Devices", data: convert(data[site][`${metric}-moma`]["All Devices"])},
            ]}
            title={`${site} ${metric} MoM #`}
            xaxis={``}
        />
        : null}
</>

const TrafficSourcesBlock = ({site, data}) => <>
    <GraphComponent
        data={[
            {id: "Search", data: convert(data[site]['Traffic Sources%']["Search"])},
            {id: "Social", data: convert(data[site]['Traffic Sources%']["Social"])},
            {id: "Paid", data: convert(data[site]['Traffic Sources%']["Paid"])},
            {id: "Referral", data: convert(data[site]['Traffic Sources%']["Referral"])},
            {id: "Direct", data: convert(data[site]['Traffic Sources%']["Direct"])},
        ]}
        title={`${site} Traffic Sources %`}
        xaxis={``}
        stacked={true}
        csv={true}
        // GraphComp={ResponsiveLine}
    />
    <GraphComponent
        data={[
            {id: "Other", data: convert(data[site]['Traffic SourcesSites%']["Other"])},
            {id: "intuit.com", data: convert(data[site]['Traffic SourcesSites%']["intuit.com"])},
            {id: "Google Paid", data: convert(data[site]['Traffic SourcesSites%']["Google Paid"])},
            {id: "Google Organic", data: convert(data[site]['Traffic SourcesSites%']["Google Organic"])},
            {id: "Direct", data: convert(data[site]['Traffic SourcesSites%']["Direct"])},
        ]}
        title={`${site} Traffic Sources (sites) %`}
        xaxis={``}
        stacked={true}
        csv={true}
        // GraphComp={ResponsiveLine}
    />
</>


export default BillSemrushPage
