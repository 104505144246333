export let sortRegions = (data) => {
    let counts = {}

    for (const [region, count] of iterateRegions(data)) {
        if (region in counts) {
            counts[region] = counts[region] + count
        } else {
            counts[region] = count
        }
    }
    return Object.keys(Object.fromEntries(
        Object.entries(counts).sort(([, a], [, b]) => b - a)
    ));
}

function* iterateRegions(dataList) {
    for (const {data} of dataList) {
        for (const value of Object.values(data)) {
            for (const [region, count] of Object.entries(value))
                yield [region, count]
        }
    }
}