import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import AppAnnieGraphPlot from "./AppAnnieGraphPlot";


const AppAnnieGraphsViewer = ({data}) => {
    if (data.length === 1) {
        let countryData = data[0];
        return <AppAnnieCountryViewer countryData={countryData.metrics} countryName={countryData.country}/>
    } else {
        return data.map(countryData => <AccordionCountryComponent countryData={countryData.metrics}
                                                                  countryName={countryData.country}
                                                                  key={countryData.country}/>)
    }
}

const AppAnnieCountryViewer = ({countryData, countryName}) => {
    return <div>
        {countryData.map(graphData => <AppAnnieGraphPlot graphData={graphData} countryName={countryName}/>)}
    </div>
}


const AccordionCountryComponent = ({countryData, countryName}) => {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{countryName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <AppAnnieCountryViewer countryData={countryData} countryName={countryName}/>
        </AccordionDetails>
    </Accordion>
}


export default AppAnnieGraphsViewer