import React from "react";
import {Auth} from "aws-amplify";
import {Navigate} from "react-router-dom";

const ProtectedRoute = ({component}) => {
    const [isAuthenticated, setLoggedIn] = React.useState(true);
    React.useEffect(() => {
        (async () => {
            let user = null;
            try {
                user = await Auth.currentAuthenticatedUser();
                if (user) {
                    setLoggedIn(true);
                } else {
                    setLoggedIn(false);
                }
            } catch (e) {
                console.log('error')
                console.log(e)
                setLoggedIn(false);
            }
        })();
    });

    return (
        <>
            {isAuthenticated ? (
                React.createElement(component)
            ) : (
                <Navigate to="/signin"/>
            )
            }
        </>
    );
};

export default ProtectedRoute;
