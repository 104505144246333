import React from 'react';


const StackedLegend = (highlightedLines, flipHighlightedLine, invisibleLines) => ({
                                                                                      series,
                                                                                      xScale,
                                                                                      yScale,
                                                                                      innerWidth,
                                                                                      innerHeight
                                                                                  }) => {
    const adjustedPositions = adjustLabelPositions(series, yScale, innerHeight);
    const xOffset = 15
    const maxX = Math.max(...series.flatMap(line => line.data.map(point => point.data.x)))
    return (
        <>
            {adjustedPositions.map(pos => {
                return (
                    invisibleLines[pos.id] ? null :
                        <text
                            key={pos.id}
                            x={xScale(maxX) + xOffset}
                            y={pos.y}
                            fill={pos.color}
                            style={{
                                fontSize: '12px',
                                fontWeight: 'bold',
                                cursor: 'pointer'
                            }}
                            onClick={() => flipHighlightedLine(pos.id)}
                            dy="-0.35em"
                        >
                            {pos.id}
                        </text>
                );
            })}
        </>
    );
};

function adjustLabelPositions(series, yScale, chartHeight, labelHeight = 11, padding = 0) {
    // Extract y positions and sort them
    let labels = series.map((line, index) => ({
        id: line.id === 'atlassian.com(with atlassian.net)' ? 'atlassian.com\n(with atlassian.net)' : line.id,
        color: line.color,
        originalY: yScale(line.data[line.data.length - 1].data.y),
        y: yScale(line.data[line.data.length - 1].data.y) + labelHeight / 2,
        index,
    })).sort((a, b) => a.originalY - b.originalY);

    // Function to check and resolve overlaps
    let resolveOverlap = (current, next, direction) => {
        const currentBottom = current.y + labelHeight / 2;
        const nextTop = next.y - labelHeight / 2;
        if (currentBottom + padding > nextTop) {
            const overlap = currentBottom + padding - nextTop;
            // current.y -= overlap / 2;
            if (direction === 'down') {
                next.y += overlap;
            } else {
                current.y -= overlap
            }


            // Ensure labels stay within chart bounds
            // if (current.y - labelHeight / 2 < 0) {
            //     current.y = labelHeight / 2;
            // }
            // if (next.y + labelHeight / 2 > chartHeight) {
            //     next.y = chartHeight - labelHeight / 2;
            // }
        }
    };


    let pushUp = (labels) => {
        if (labels[labels.length - 1].y + labelHeight / 2 > chartHeight) {
            labels[labels.length - 1].y = chartHeight
        }
        for (let i = labels.length - 2; i >= 0; i--) {
            resolveOverlap(labels[i], labels[i + 1], 'up');
        }

        return labels
    }

    // Iterate over the labels to resolve overlaps
    for (let i = 0; i < labels.length - 1; i++) {
        resolveOverlap(labels[i], labels[i + 1], 'down');
    }

    pushUp(labels)


    // Sort back to original order to maintain data series order
    return labels.sort((a, b) => a.index - b.index);
}


export default StackedLegend;