import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/dataApi.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";
import TimeLinePlot from "../../../../components/Plots/TimeLinePlot/TimeLinePlot";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";


let path = 'productivity/builtwith';

const BuiltwithPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Productivity - Built With
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}

const Graphs = () => {
    let data = useSelector(state => state.dataApi.data[path])
    return <div>
        <TimeLinePlotWithSettings data={data['Entire Internet']} title={'Entire Internet'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={data['Top 1m']} title={'Top 1m'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={data['Top 100k']} title={'Top 100k'} yFormat=" >-d"/>
        <TimeLinePlotWithSettings data={data['Top 10k']} title={'Top 10k'} yFormat=" >-d"/>
    </div>
}

export default BuiltwithPage