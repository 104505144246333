function filterLastYear(data) {
    if (!data || data.length === 0) return [];

    // Find the most recent date
    const maxDate = new Date(Math.max(
        ...data.map(item => new Date(item.x))
    ));

    // Calculate the date one year ago from the most recent date
    const oneYearAgo = new Date(maxDate);
    oneYearAgo.setFullYear(maxDate.getFullYear() - 1);

    // Filter data points from the last year
    return data.filter(item => {
        const itemDate = new Date(item.x);
        return itemDate >= oneYearAgo;
    });
}

function filterLastYear2(data) {
    if (!Array.isArray(data) || data.length === 0) return [];

    // Flatten all dates from all nested data arrays
    const allDates = data.reduce((dates, item) => {
        if (!item.data || !Array.isArray(item.data)) return dates;
        return [...dates, ...item.data.map(d => new Date(d.x))];
    }, []);

    if (allDates.length === 0) return [];

    // Find the most recent date
    const maxDate = new Date(Math.max(...allDates));

    // Calculate the date one year ago from the most recent date
    const oneYearAgo = new Date(maxDate);
    oneYearAgo.setFullYear(maxDate.getFullYear() - 1);

    // Filter each nested data array
    return data.map(item => ({
        id: item.id,
        data: item.data.filter(dataPoint => {
            const itemDate = new Date(dataPoint.x);
            return itemDate >= oneYearAgo;
        })
    }));
}


function filter2024Dates(data) {
    if (!data || data.length === 0) return [];

    // Filter out dates from 2024 and beyond
    return data.filter(item => {
        const itemYear = new Date(item.x).getFullYear();
        return itemYear >= 2024;
    });
}

function filter2024Dates2(nestedData) {
    if (!Array.isArray(nestedData) || nestedData.length === 0) return [];

    // Filter each nested data array
    return nestedData.map(item => ({
        id: item.id,
        data: item.data.filter(dataPoint => {
            const itemYear = new Date(dataPoint.x).getFullYear();
            return itemYear >= 2024;
        })
    }));
}

export function filterTime(data, timeSelectorParams) {
    if (timeSelectorParams === null) {
        return data;
    } else if (timeSelectorParams === "last year") {
        return filterLastYear(data);
    } else if (timeSelectorParams === ">2024") {
        return filter2024Dates(data);
    } else {
        throw new Error(`Invalid timeSelectorParams: ${timeSelectorParams}`);
    }
}


export function filterTime2(data, timeSelectorParams) {
    if (timeSelectorParams === null) {
        return data;
    } else if (timeSelectorParams === "last year") {
        return filterLastYear2(data);
    } else if (timeSelectorParams === ">2024") {
        return filter2024Dates2(data);
    } else {
        throw new Error(`Invalid timeSelectorParams: ${timeSelectorParams}`);
    }
}