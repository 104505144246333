import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {apiCall} from "./api_helpers";

export const dataApiSlice = createSlice({
    name: 'dataApi',
    initialState: {
        data: {},
    },
    reducers: {
        setData: (state, action) => {
            state.data[action.payload.path] = action.payload.data
        },
    },
    extraReducers(builder) {
        builder
            .addCase(fetchData.pending, (state, action) => {
                state.data[action.meta.arg.path] = undefined
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.data[action.meta.arg.path] = action.payload
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.error = action.error.message
                console.log(state.error)
            })
    }
})


export const fetchData = createAsyncThunk('dataApi/fetchData', async ({path}) => {
    return apiCall(path)()
})

export const {setData,} = dataApiSlice.actions

export default dataApiSlice.reducer

