import React from "react";
import {isNotEmpty} from "../../common/js_helpers";

function withNonEmptyData(GraphComponent) {

    return function DataPageComponent({data, ...comps}) {
        return isNotEmpty(data) ? <GraphComponent data={data} {...comps} /> : null
    };
}

export default withNonEmptyData