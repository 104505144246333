import React from "react";
import {SimpleDisplayTable} from "../table/SimpleDisplayTable";
import {getUniqueKeysOfNestedDicts, roundNumber} from "../../../common/data_helpers";


const StackOverflowSurveyComponent = ({data: {survey: {worked_with, want}}}) => {
    console.log(worked_with)
    return <div>
        <SimpleDisplayTable data={worked_with}
                            timePeriods={getUniqueKeysOfNestedDicts(worked_with).slice(-16)}
                            numberFormat={n => `${roundNumber(n, 0)}%`}
                            title={"Stackoverflow Surveys - Worked With [Percentage of Respondents]"}
        />

        <div style={{height: '50px'}}></div>


        <SimpleDisplayTable data={want}
                            timePeriods={getUniqueKeysOfNestedDicts(want).slice(-16)}
                            numberFormat={n => `${roundNumber(n, 0)}%`}
                            title={"Stackoverflow Surveys - Want [Percentage of Respondents]"}
        />

        {/*<TimeLinePlotWithSettings data={worked_with} title={'Indeed.com job counts'} yFormat=" >-d" defaultMaxY={15}/>*/}

    </div>
}

export default StackOverflowSurveyComponent