import React, {useRef, useState} from "react";
import Typography from "@mui/material/Typography";
import {ResponsiveLine, ResponsiveLineCanvas} from "@nivo/line";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import Paper from "@mui/material/Paper";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";
import Box from "@mui/material/Box";
import RenderIfVisible from "react-render-if-visible";
import TimeSelector from "../../TimeSelector/TimeSelector";
import {filterTime} from "../../TimeSelector/timeSelectorHelpers";

const GraphComponent = React.forwardRef(({data, title, xaxis, GraphComp = ResponsiveLineCanvas}, ref) => {
    const {height} = useWindowSize();

    return <div ref={ref}
                style={{height: getMobileAdjustedHeight(height, 500) - 60,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {title}
        </Typography>
        <GraphComp
            data={[{
                id: 'graph',
                data: data,
            }]}
            margin={{top: 15, right: 15, bottom: 65, left: 50}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: false,
                min: 'auto'
            }}
            axisLeft={{
                legend: xaxis,
                legendOffset: 12,
            }}
            axisBottom={{
                format: '%y %b',
                legend: 'year month',
                legendOffset: -12,
            }}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={false}
            animate={false}

        />
    </div>
})


let GraphWithPrint = ({data, ...comps}) => {
    const componentRef = useRef();
    const {height} = useWindowSize();

    const [timeSelectorParams, updateTimeSelectorParams] = useState(null);

    return (
        <React.Fragment>

            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px'
                }
            }>

                <RenderIfVisible
                    defaultHeight={getMobileAdjustedHeight(height, 500) - 60}
                >
                    <GraphComponent ref={componentRef} GraphComp={ResponsiveLine}
                                    data={filterTime(data, timeSelectorParams)}{...comps}/>
                </RenderIfVisible>
                <Box display="flex">
                    <Button
                        variant={"text"}
                        style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
                        onClick={() => exportComponentAsPNG(componentRef)}
                    >
                        Export As PNG
                    </Button>
                    <Box sx={{marginLeft: 'auto'}}>
                        <TimeSelector

                            timeSelectorParams={timeSelectorParams}
                            updateTimeSelectorParams={updateTimeSelectorParams}
                        />
                    </Box>

                </Box>
            </Paper>
        </React.Fragment>
    )
        ;

}


export default GraphWithPrint