import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Typography from "@mui/material/Typography";

let TimeLineSettings = ({settings, slideInRight}) => {
    const [magLabel, magOrder] = settings['magnitude']
    const [visibleLines, setVisibleLines] = settings['visibleLines']
    // const [fadeIn, setFadeIn] = useState(false);

    // useEffect(() => {
    //     // Trigger the fade-in effect when the component mounts
    //     setFadeIn(true);
    // }, []);
    return <div className={slideInRight ? 'slideInRight' : ''}>
        <Box display="flex">
            <TextField id="standard-basic" label={`Max Value ${magLabel}`} variant="standard"
                       value={settings['maxY'][0] ? settings['maxY'][0] : ''}
                       type="number"
                       onChange={(event) => {
                           settings['maxY'][1](event.target.value);
                       }}
                       InputLabelProps={{
                           shrink: settings['maxY'][0] !== undefined, // This ensures the label shrinks when there is a value
                       }}
            />
            <Button
                onClick={() => settings['maxY'][1](undefined)}
            ><CancelIcon/></Button>
        </Box>
        <Box display="flex">
            <TextField id="standard-basic" label={`Min Value ${magLabel}`} variant="standard"
                       value={settings['minY'][0] ? settings['minY'][0] : ''}
                       type="number"
                       onChange={(event) => {
                           settings['minY'][1](event.target.value);
                       }}
                       InputLabelProps={{
                           shrink: settings['minY'][0] !== undefined, // This ensures the label shrinks when there is a value
                       }}
            />
            <Button
                onClick={() => settings['minY'][1](undefined)}
            ><CancelIcon/></Button>
        </Box>
        {settings['smoothingUnit'] !== undefined ?
            <Box display="flex">
                <TextField id="standard-basic" label={`Smoothing [${settings['smoothingUnit']}]`} variant="standard"
                           value={settings['smoothing'][0] ? settings['smoothing'][0] : ''}
                           type="number"
                           onChange={(event) => {
                               settings['smoothing'][1](event.target.value);
                           }}
                           InputLabelProps={{
                               shrink: settings['smoothing'][0] !== undefined, // This ensures the label shrinks when there is a value
                           }}
                />
                <Button
                    onClick={() => settings['smoothing'][1](undefined)}
                ><CancelIcon/></Button>
            </Box> : null
        }
        <Box marginTop={1}>
            <Typography fontWeight="bold">Visibility </Typography>
            {Object.entries(visibleLines).map(([key, value], index) => (
                <Box display="flex" alignItems="center" height={25}>
                    <Box flexGrow={1}>
                        <Typography>{key}</Typography>
                    </Box>
                    <Box>
                        <Button
                            onClick={() => setVisibleLines({...visibleLines, [key]: !visibleLines[key]})}
                        >{!value ? <VisibilityIcon/> : <VisibilityOffIcon/>}</Button>
                    </Box>
                </Box>
                // <KeyValueComponent key={index} keyName={key} value={value} />
            ))}
        </Box>

    </div>
}

export default TimeLineSettings