import React, {useRef} from "react";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import {ResponsiveBar} from '@nivo/bar'
import moment from "moment";
import Typography from "@mui/material/Typography";

const TimeBar = React.forwardRef(({
                                      data, title, xaxis, stacked = false,
                                      axisBottomParams = {},
                                      timeFormat = "YY MMM",

                                  }, ref) => {
    const {height} = useWindowSize();

    return <div ref={ref}
                style={{height: getMobileAdjustedHeight(height, 500) - 60,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {title}
        </Typography>
        <ResponsiveBar
            data={data}
            indexBy="x"
            keys={['y']}
            // xScale={{
            //     type: 'time',
            //     format: '%Y-%m-%d',
            //     useUTC: false,
            //     precision: 'day',
            // }}
            axisBottom={{
                format: (v) => moment(v).format(timeFormat),
                legendOffset: -12,
                tickRotation: -90
                // ...axisBottomParams
            }}
            margin={{top: 10, right: 25, bottom: 75, left: 25}}
        />
    </div>


})


let TimeBarWithPrint = ({buttonStyle = {}, ...comps}) => {
    const {height} = useWindowSize();

    const componentRef = useRef();
    return (
        <React.Fragment>
            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px',
                }
            }>
                <TimeBar ref={componentRef} {...comps}/>
                <Button
                    variant={"text"}
                    style={{
                        marginTop: 5,
                        marginBottom: 20,
                        ...buttonStyle
                    }}
                    onClick={() => exportComponentAsPNG(componentRef)}
                >
                    Export As PNG
                </Button>
            </Paper>
        </React.Fragment>
    );

}


export default TimeBarWithPrint