import {CssBaseline} from "@mui/material";
import Amplify from "aws-amplify";
import {COGNITO} from "./configs/aws";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import SignIn from "./pages/signin/SignIn";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";

Amplify.configure({
    aws_cognito_region: COGNITO.REGION,
    aws_user_pools_id: COGNITO.USER_POOL_ID,
    aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
    API: {
        endpoints: [
            {
                name: "blah",
                endpoint: "https://frbfjwsr5f.execute-api.us-east-1.amazonaws.com/prod/"
            },
            {
                name: "data",
                endpoint: "https://j8g3iabdu1.execute-api.us-east-1.amazonaws.com/prod/"
            },
        ]
    }
});


function App() {
    return (
        <Router>
            <CssBaseline/>
            <Routes>
                <Route path='/signin' element={<SignIn/>}/>
                <Route path='*' element={<ProtectedRoute component={Dashboard}/>}/>
            </Routes>
        </Router>
    );
}

export default App;
