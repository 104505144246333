import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/amzn.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import CohortTablePlot from "../../../../components/Plots/CohortTable/CohortTablePlot";
import Paper from "@mui/material/Paper";
import {convertDistributionData} from "../../../../components/Plots/CohortTable/dataHelpers";


let path = 'amzn/bwp_v2';

const BuyWithPrimePage = () => {
    let data = useSelector(state => state.amzn.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                AMZN - Buy With Prime
            </Typography>
        </Container>
        <Container>
            {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.amzn.data[path])
    return <>
        <ShopGraphs shopData={data['shopify']} shopName="Shopify"/>

    </>
}


const ShopGraphs = ({shopData, shopName}) => <>
    <Typography variant="h5" color="inherit" component="div">
        {shopName}
    </Typography>
    <GraphComponent
        data={convert(shopData['installed_bwp'])}
        title={`${shopName} - Installed BWP`}
        xaxis={'# domains with installed bwp'}
    />
    <GraphComponent
        data={convert(shopData['active_shops'])}
        title={`${shopName} - Active shop domains`}
        xaxis={'# active shop domains'}
    />
    <Paper sx={
        {
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 500,
            marginTop: '50px',
        }
    }>
        <Typography variant="h5" color="inherit" component="div">
            {shopName} - Installed BWP Cohort Absolute
        </Typography>
        <CohortTablePlot
            data={convertDistributionData(shopData['installed_cohort'])}
            yLabel={"Count"}
        />
    </Paper>
    <Paper sx={
        {
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 500,
            marginTop: '50px',
        }
    }>
        <Typography variant="h5" color="inherit" component="div">
            {shopName} - Installed BWP Cohort Percentage
        </Typography>
        <CohortTablePlot data={convertDistributionData(shopData['installed_cohort_percentage'])}/>
    </Paper>

</>

export default BuyWithPrimePage
