import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data.reducer";
import GraphComponent from "../../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";


let path = 'flight_data/asm';

const ASMPage = () => {
    let data = useSelector(state => state.flightData.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - ASM
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.flightData.data[path])
    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <Typography variant="h5" color="inherit" component="div">
            T100
        </Typography>
        <Typography variant="body1" color="inherit" component="div">
            T100 - US and international. International data only to or from US.
        </Typography>
        <GraphSection data={data['t100']} calculation={'Totals'} title={'T100 - Totals [mil]'}/>
        <GraphSection data={data['t100']} calculation={'YOY'} title={'T100 - YOY'}/>
        <GraphSection data={data['t100']} calculation={'YOY2'} title={'T100 - YOY2'}/>
        <Typography variant="h5" color="inherit" component="div">
            T1
        </Typography>
        <Typography variant="body1" color="inherit" component="div">
            T1 - US domestic only
        </Typography>
        <GraphSection data={data['t1']} calculation={'Totals'} title={'T1 - Totals [mil]'}/>
        <GraphSection data={data['t1']} calculation={'YOY'} title={'T1 - YOY'}/>
        <GraphSection data={data['t1']} calculation={'YOY2'} title={'T1 - YOY2'}/>
    </Container>

}


let GraphSection = ({data, calculation, title}) =>
    <GraphComponent
        data={convert(data[calculation])}
        title={`Flight Radar - ${title ? title : calculation}`}
        xaxis={title ? title : calculation}
    />


export default ASMPage
