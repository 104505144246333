import {Auth} from "aws-amplify";

export let apiUrl = 'https://0fk4vkuadc.execute-api.us-east-1.amazonaws.com/prod/'

export let apiCall = (path) => {
    let fun = async () => {
        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
        };
        const response = await fetch(`${apiUrl}/${path}`, requestOptions);
        return await response.json();
    }
    return fun
}

