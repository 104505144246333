import React from "react";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import {convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../components/Plots/GraphComponent/GraphComponent";
import Typography from "@mui/material/Typography";


let path = 'datadog/open_telemetry_v2';

const OpenTelemetryGraphs = ({data: {collector, total_repos, repo_count}}) => {
    console.log(collector)
    return <>
        <RepoGraphs data={collector} title="Collector Repository"/>

        <RepoGraphs data={total_repos} title="All Repositories"/>


        <GraphComponent
            data={convert(repo_count)}
            title={'Repository Count'}
        />
    </>
}


const RepoGraphs = ({data, title}) => {
    return <>
        <Typography variant='h5' sx={{marginBottom: '10px'}}>{title}</Typography>
        <GraphComponent
            data={convert(data.commits)}
            title={`${title} - Number of commits per month`}
        />
        <GraphComponent
            data={convert(data.contributors)}
            title={`${title} - Number of contributors per month`}
        />
        <GraphComponent
            data={convert(data.stars_per_month)}
            title={`${title} - Number of stars per month`}
        />
        <GraphComponent
            data={convert(data.stars)}
            title={`${title} - Number of stars total`}
        />
    </>
}


export default withDataFetchAndTitle(OpenTelemetryGraphs, path, 'Datadog - Open Telemetry Github')