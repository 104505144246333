import React from 'react';
import {Box, Typography} from '@mui/material';

const Methodology = () => {
    return (
        <Box component="section" sx={{padding: 2}}>
            <Typography variant="h5" gutterBottom>
                Github Stars Methodology
            </Typography>
            <Box component="section" sx={{marginLeft: 2}}>

                <Typography paragraph>
                    The graphs show the cumulative number of github stars give to a specific github repo and their
                    change over time.
                </Typography>

            </Box>
        </Box>
    );
};

export default Methodology;
