import React, {PureComponent, useRef} from 'react';
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import Typography from "@mui/material/Typography";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const BiaxialPlot = React.forwardRef(({data, lname, rname, title}, ref) => {
        const {height} = useWindowSize();

        return <div
            ref={ref}
            style={{height: getMobileAdjustedHeight(height, 500),}}
        >
            <Typography variant="h6" color="inherit" component="div" align="center">
                {title}
            </Typography>
            <ResponsiveContainer width="100%" height={getMobileAdjustedHeight(height, 500) - 100}>
                <LineChart
                    width={500}
                    height={getMobileAdjustedHeight(height, 500) - 50}
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    dot={false}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="name"/>
                    <YAxis yAxisId="left"/>
                    <YAxis yAxisId="right" orientation="right"/>
                    <Tooltip/>
                    <Legend/>
                    <Line yAxisId="left" type="monotone" dataKey={lname} stroke="#8884d8"/>{/* activeDot={{r: 8}}*/}
                    <Line yAxisId="right" type="monotone" dataKey={rname} stroke="#82ca9d"/>
                </LineChart>
            </ResponsiveContainer>
        </div>
    }
)


let GraphWithPrint = ({...comps}) => {
    const {height} = useWindowSize();

    const componentRef = useRef();
    return (
        <React.Fragment>
            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px'
                }
            }>
                <BiaxialPlot ref={componentRef} {...comps}/>
                <Button
                    variant={"text"}
                    style={{marginTop: 5, marginBottom: 20}}
                    onClick={() => exportComponentAsPNG(componentRef)}
                >
                    Export As PNG
                </Button>
            </Paper>
        </React.Fragment>
    );

}


export default GraphWithPrint
// export default BiaxialPlot