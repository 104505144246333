import React, {useRef, useState} from "react";
import {ResponsiveLine, ResponsiveLineCanvas} from "@nivo/line";
import {getMobileAdjustedHeight, useWindowSize} from "../plotsHelpers";
import Paper from "@mui/material/Paper";
import RenderIfVisible from "react-render-if-visible";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {exportComponentAsPNG} from "react-component-export-image";
import Typography from "@mui/material/Typography";
import CustomSliceTooltip from "../Tooltips/CustomSliceTooltip";
import TuneIcon from '@mui/icons-material/Tune';
import {convert} from "../GraphComponent/GraphComponent.helpers";
import {mapObjectValues, normalizeData, trailingAverage} from "../../../common/data_helpers";
import AreaPlotSettings from "./AreaPlotSettings";


const GraphComponent = React.forwardRef(({
                                             data,
                                             title,
                                             xaxis,
                                             GraphComp = ResponsiveLineCanvas,
                                             lineProps = {},
                                             invisibleLines = {},
                                             smoothing,
                                             normalize,
                                             yFormatFun = undefined,
                                             defaultOrderOfMagnitude = undefined
                                         }, ref) => {
    console.log(data)
    const {height} = useWindowSize();
    const convertedData = []

    let normalizedData = normalize ? normalizeData(data) : data
    let smoothedData = mapObjectValues(normalizedData, trailingAverage(smoothing))
    // const [magLabel, magOrder] = defaultOrderOfMagnitude === undefined ? getOrderOfMagnitude(findLargestY(data)) : defaultOrderOfMagnitude

    Object.keys(smoothedData).forEach(key => {
        convertedData.push(
            {
                id: key, data: convert(smoothedData[key])
            },
        )
    })

    let yFormatLinePropsAdd = (yFormatFun === undefined || normalize) ? {} : {
        yFormat: yFormatFun,
        axisLeft: {
            format: yFormatFun,
        },
    }

    console.log(convertedData)


    return <div ref={ref}
                style={{height: getMobileAdjustedHeight(height, 500) - 60,}}>
        <Typography variant="h6" color="inherit" component="div" align="center">
            {title}
        </Typography>
        <ResponsiveLine
            data={convertedData}
            margin={{top: 50, right: 150, bottom: 50, left: 60}}
            xScale={{
                type: 'time',
                format: '%Y-%m-%d',
                useUTC: false,
                precision: 'day',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{
                type: 'linear',
                stacked: true,
            }}
            colors={['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5']
            }

            // axisLeft={{
            //     legend: 'linear scale',
            //     legendOffset: 12,
            // }}
            // layers={['grid', 'markers', 'areas', 'slices', 'points', 'axes', 'mesh', 'crosshair', 'legends']}
            axisBottom={{
                format: '%y %b',
                // tickValues: 'every 6 months',
                // legend: 'time scale',
                legendOffset: -12,
            }}
            // curve={select('curve', curveOptions, 'monotoneX')}
            // enablePointLabel={true}
            // pointSymbol={CustomSymbol}
            pointSize={0}
            pointBorderWidth={1}
            pointBorderColor={{
                from: 'color',
                modifiers: [['darker', 0.3]],
            }}
            useMesh={true}
            enableSlices={"x"}
            enableArea={true}
            areaOpacity={1}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 100,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemDirection: 'left-to-right',
                    itemWidth: 80,
                    itemHeight: 20,
                    itemOpacity: 0.75,
                    symbolSize: 5,
                    symbolShape: 'circle',
                    symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    // onClick: (l) => lineIndex === l.id ? setLineIndex("") : setLineIndex(l.id),
                    // effects: [
                    //     {
                    //         on: 'hover',
                    //         style: {
                    //             itemBackground: 'rgba(0, 0, 0, .1)',
                    //             itemOpacity: 1,
                    //         }
                    //     }
                    // ]
                }
            ]}
            // sliceTooltip={InterpolationSliceTooltip(data, color.values)}
            sliceTooltip={CustomSliceTooltip(false)}
            {...{...lineProps, ...yFormatLinePropsAdd}}
        />
    </div>
})

let GraphWithPrint = ({defaultSmoothing, smoothingUnit, defaultNormalize, ...comps}) => {
    const componentRef = useRef();
    const {height} = useWindowSize();
    const [showSettings, setShowSettings] = useState(false)
    const [smoothing, setSmoothing] = useState(defaultSmoothing)
    const [normalize, setNormalize] = useState(defaultNormalize)
    let settings = {
        smoothingUnit: smoothingUnit,
        smoothing: [smoothing, setSmoothing],
        normalize: [normalize, setNormalize]
    }

    return (
        <React.Fragment>

            <Paper sx={
                {
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: getMobileAdjustedHeight(height, 500),
                    marginBottom: '50px'
                }
            }>

                <RenderIfVisible
                    defaultHeight={getMobileAdjustedHeight(height, 500) - 60}
                >
                    <Box display="flex">
                        <Box flexGrow={1} width={0}>
                            <GraphComponent ref={componentRef} GraphComp={ResponsiveLine}
                                            smoothing={smoothing}
                                            normalize={normalize}
                                            {...comps}/>
                        </Box>
                        {showSettings ? <Box>
                            <AreaPlotSettings settings={settings} slideInRight={showSettings}/>
                        </Box> : null}
                    </Box>
                    {/*<StackedLegend data={comps.data}/>*/}
                </RenderIfVisible>
                <Box display="flex">
                    <Button
                        variant={"text"}
                        style={{marginTop: 5, marginBottom: 20, marginLeft: 15}}
                        onClick={() => exportComponentAsPNG(componentRef)}
                    >
                        Export As PNG
                    </Button>
                    <Button
                        style={{marginTop: 5, marginBottom: 20, marginLeft: "auto"}}
                        onClick={() => setShowSettings(!showSettings)}
                    >
                        <TuneIcon/>
                    </Button>
                </Box>
            </Paper>
        </React.Fragment>
    )
        ;

}


export default GraphWithPrint