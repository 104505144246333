import React from "react";
import AreaPlot from "../../../components/Plots/AreaPlot/AreaPlot";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import {convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import GraphComponent from "../../../components/Plots/GraphComponent/GraphComponent";


let path = 'datadog/cncf_github';

const CNCFGraphs = ({data}) => {
    console.log(data)
    return <>
        <GraphComponent
            data={convert(data.commits)}
            title={`Number of commits per month`}
        />
        <GraphComponent
            data={convert(data.contributors)}
            title={`Number of contributors per month`}
        />
        <GraphComponent
            data={convert(data.stars_per_month)}
            title={`Number of stars per month`}
        />
        <GraphComponent
            data={convert(data.stars)}
            title={`Number of stars total`}
        />
    </>
}


export default withDataFetchAndTitle(CNCFGraphs, path, 'Datadog - Observability Github')