import React from "react";
import {Table, TableBody, TableCell, TableRow} from '@mui/material';
import Paper from "@mui/material/Paper";
import {roundNumber} from "../../../common/data_helpers";
import Typography from "@mui/material/Typography";
import {YearGraph} from "./CommonGraphs";


const NumberOfCustomersGraph = ({numberOfCustomers, title, colors}) => {
    const {series, r2corr} = numberOfCustomers
    return <>
        <YearGraph series={series} title={title} colors={colors}/>
        <CorrelationTable r2corr={r2corr}/>
    </>

}


const CorrelationTable = ({r2corr}) => {
    const reversedKeys = Object.keys(r2corr).reverse();
    return (
        <Paper>
            <Typography variant="h6" color="inherit" component="div" align="center">
                Actual vs dataset count correlation
            </Typography>
            <Table>
                <TableBody>
                    {reversedKeys.map(key => (
                        <TableRow key={key}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{roundNumber(r2corr[key], 4)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    );
}


export default NumberOfCustomersGraph