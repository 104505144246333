import { ResponsiveBump } from '@nivo/bump'

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const BumpPlot = ({ data /* see data tab */ }) => {
    console.log(data)
    return (
        <ResponsiveBump
            data={data}
            colors={{scheme: 'spectral'}}
            lineWidth={3}
            activeLineWidth={6}
            inactiveLineWidth={3}
            inactiveOpacity={0.15}
            pointSize={10}
            activePointSize={16}
            inactivePointSize={0}
            pointColor={{theme: 'background'}}
            pointBorderWidth={3}
            activePointBorderWidth={3}
            pointBorderColor={{from: 'serie.color'}}
            axisTop={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -36
            }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 32
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'ranking',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            margin={{top: 40, right: 100, bottom: 40, left: 60}}
            axisRight={null}
        />
    );
}

export default BumpPlot