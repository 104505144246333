import {ResponsiveLine} from '@nivo/line'
import {useState} from "react";


const lineStyles = {
    emphasized: {
        strokeWidth: 8,
    },
    default: {
        strokeWidth: 3,
    },
}

export const DashedLine = (highlightedLines, invisibleLines) => ({series, lineGenerator, xScale, yScale}) => {
    const setLineStyle = (id) => {
        if (invisibleLines[id]) {
            return {stroke: 'transparent', strokeWidth: 0, pointSize: 0};
        } else {
            return highlightedLines[id] ? lineStyles.emphasized : lineStyles.default
        }
    }

    return series.filter(({id}) => !invisibleLines[id]).map(({id, data, color}) => (
        <path
            key={id}
            d={lineGenerator(
                data.map(d => ({
                    x: xScale(d.data.x),
                    y: yScale(d.data.y),
                }))
            )}
            fill="none"
            stroke={color}
            style={setLineStyle(id)}
        />
    ))
}


const MyResponsiveLine = ({data}) => {
    let [lineIndex, setLineIndex] = useState("")

    let updateIndex = (l) => {
        if (l.serieId === lineIndex) {
            setLineIndex("")
        } else {
            setLineIndex(l.serieId)
        }
    }

    let DLC = DashedLine(lineIndex)

    return <ResponsiveLine
        onClick={updateIndex}
        // onMouseMove={l => updateIndex(l)}
        // onMouseLeave={() => setLineIndex("")}
        data={data}
        margin={{top: 50, right: 110, bottom: 50, left: 60}}
        curve="linear"
        xScale={{
            type: 'point',
            min: 'auto',
            max: 'auto',
        }}
        yScale={{
            type: 'linear',
            min: 0,
            max: 'auto',
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        layers={['grid', 'markers', 'areas', DLC, 'slices', 'points', 'axes', 'mesh', 'crosshair', 'legends']}
        pointSize={5}
        pointColor={{theme: 'background'}}
        pointBorderWidth={2}
        pointBorderColor={{from: 'serieColor'}}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 5,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                onClick: (l) => lineIndex === l.id ? setLineIndex("") : setLineIndex(l.id),
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .1)',
                            itemOpacity: 1,
                        }
                    }
                ]
            }
        ]}
    />
}

export default MyResponsiveLine;