import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import LoadingSpinnerComponent from "../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {fetchData} from "../../../store/billdotocom.reducer";
import BiaxialGraph from "../../../components/Plots/BiaxialPlot/BiaxialPlot";
import {prepare_data} from "../../../components/Plots/BiaxialPlot/data_prep";
import {getObjectDecimalRounder} from "../../../common/data_helpers";


let path = 'billdotcom/divvy';

const DivvyPage = () => {
    let data = useSelector(state => state.billdotcom.data)
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Bill.com - Divvy
            </Typography>
        </Container>
        <Container>
            {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}


const Graphs = () => {

    return <>
        <Graph
            title={"Divvy Daily Downloads"}
            laxixName={`downloads [left]`}
            ldata={'downloads'}
            raxisName={`downloads_yoy % [right]`}
            rdata={'downloads_yoy'}
        />

        <Graph
            title={"Divvy Monthly Downloads"}
            laxixName={`downloads [left]`}
            ldata={'downloads_monthly'}
            raxisName={`downloads_yoy % [right]`}
            rdata={'downloads_monthly_yoy'}
        />


        <Graph
            title={"Last Year Divvy Daily Downloads"}
            laxixName={`downloads [left]`}
            ldata={'last_year_downloads'}
            raxisName={`downloads_yoy % [right]`}
            rdata={'last_year_downloads_yoy'}
        />


        <Graph
            title={"Last Year Divvy Monthly Downloads"}
            laxixName={`downloads [left]`}
            ldata={'last_year_downloads_monthly'}
            raxisName={`downloads_yoy % [right]`}
            rdata={'last_year_downloads_monthly_yoy'}
        />


    </>
}

const Graph = ({laxixName, raxisName, title, ldata, rdata}) => {
    let data = useSelector(state => state.billdotcom.data[path])
    return <BiaxialGraph
        data={prepare_data(
            getObjectDecimalRounder(2)(data[ldata]),
            laxixName,
            getObjectDecimalRounder(2)(data[rdata]),
            raxisName
        )}
        lname={laxixName}
        rname={raxisName}
        title={title}
    />

}


export default DivvyPage
