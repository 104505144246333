import withDataFetchAndTitle from "../../components/DataLoadPage";
import React from "react";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {thousandsFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import GithubStarsMethodology from "../snowflake/GithubStarsMethodology";
import SnowflakeGithubStarsMethodology from "../snowflake/SnowflakeGithubStarsMethodology";

const GithubStars = ({data}) => {
    console.log(data)
    return <div style={{paddingBottom: 100}}>
        <Graph plotData={data['MongoDB Atlas']} title={'MongoDB Atlas'}/>
        <Graph plotData={data['MongoDB Repos']} title={'MongoDB Repos'}/>
        {/*<Accordion TransitionProps={{unmountOnExit: true}}>*/}
        {/*    <AccordionSummary*/}
        {/*        expandIcon={<ExpandMoreIcon/>}*/}
        {/*        aria-controls="panel1a-content"*/}
        {/*        id="panel1a-header"*/}
        {/*    >*/}
        {/*        <Typography>{'Snowflake Github Stars Methodology'}</Typography>*/}
        {/*    </AccordionSummary>*/}
        {/*    <AccordionDetails>*/}
        {/*        <SnowflakeGithubStarsMethodology/>*/}
        {/*    </AccordionDetails>*/}
        {/*</Accordion>*/}

        {/*<Accordion TransitionProps={{unmountOnExit: true}}>*/}
        {/*    <AccordionSummary*/}
        {/*        expandIcon={<ExpandMoreIcon/>}*/}
        {/*        aria-controls="panel1a-content"*/}
        {/*        id="panel1a-header"*/}
        {/*    >*/}
        {/*        <Typography>{'Iceberg and Streamlit Github Stars Methodology'}</Typography>*/}
        {/*    </AccordionSummary>*/}
        {/*    <AccordionDetails>*/}
        {/*        <GithubStarsMethodology/>*/}
        {/*    </AccordionDetails>*/}
        {/*</Accordion>*/}
    </div>
    // return <div>{JSON.stringify(data)}</div>
}


const Graph = ({plotData, title}) => {
    return <TimeLinePlotWithSettings data={plotData} title={title}
                                     defaultSmoothing={null}
                                     smoothingUnit={"months"}
                                     yFormatFun={thousandsFormat}/>
}

export default withDataFetchAndTitle(GithubStars, 'mongodb/github_stars', 'MongoDB - Github Stars')
