import React, {useState} from "react";
import withDataFetchAndTitle from "../../components/DataLoadPage";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@mui/material';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import {mostCommon} from "../../../common/data_helpers";
import TextField from "@mui/material/TextField";
import LoopnetViewTable from "./LoopnetViewTable";

const SFOfficesTable = ({data}) => {
    let history = data['history'];
    let latestDate = data['latest_date']
    let [zipCode, setZipCode] = useState("")
    const [minPrice, setMinPrice] = useState()
    const [maxPrice, setMaxPrice] = useState()
    let filteredElements = history
        .filter((item) => ((minPrice === undefined | minPrice === "") | (item.price_processed !== null && item.price_processed >= minPrice * 1e6)))
        .filter((item) => ((maxPrice === undefined | maxPrice === "") | (item.price_processed !== null && item.price_processed <= maxPrice * 1e6)))
    let allZipCodes = mostCommon(filteredElements.map(obj => obj.zip_code))
    return <>
        <Box display="flex" sx={{alignItems: "center", justifyContent: "flex-start"}}>
            <ZipCodeFilter zipCode={zipCode} setZipCode={setZipCode} allZipCodes={[...allZipCodes].sort()}/>
            <PriceFilter label={'Min Price [mil]'} value={minPrice} setValue={setMinPrice}/>
            <PriceFilter label={'Max Price [mil]'} value={maxPrice} setValue={setMaxPrice}/>
        </Box>
        <LoopnetViewTable rows={filteredElements
            .filter((item) => (zipCode === "" | item.zip_code === zipCode))
            .map((item) => ({
                ...item,
                status: item.last_seen === latestDate ? "Active" : "Delisted"
            }))
            .sort((a, b) => b.price_processed - a.price_processed)
        }
                          latestDate={latestDate}
        />

    </>
}


const ZipCodeFilter = ({zipCode, setZipCode, allZipCodes}) => {
    const handleChange = (event) => {
        setZipCode((prevZipCode) => event.target.value);
    };
    return <Box display="flex" sx={{alignItems: "center", justifyContent: "flex-start", width: '250px'}}>
        <FormControl fullWidth sx={{maxWidth: '200px', marginBottom: '20px'}}>
            <InputLabel id="demo-simple-select-label">Zip code filter</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={zipCode}
                label="Zip code filter"
                onChange={handleChange}
            >
                {allZipCodes.map((zc) => <MenuItem key={zc[0]} value={zc[0]}>{zc[0]} ({zc[1]})</MenuItem>)}
            </Select>
        </FormControl>
        <Button sx={{marginBottom: "17px"}}
                onClick={() => setZipCode("")}
        ><CancelIcon/></Button>
    </Box>
}


const PriceFilter = ({label, value, setValue}) => {

    return <Box display="flex" sx={{marginBottom: "17px"}}>
        <TextField id="standard-basic" label={label} variant="standard"
                   value={value ? value : ''}
                   type="number"
                   onChange={(event) => {
                       setValue(event.target.value);
                   }}
                   InputLabelProps={{
                       shrink: value !== undefined, // This ensures the label shrinks when there is a value
                   }}
        />
        <Button
            onClick={() => setValue(undefined)}
        ><CancelIcon/></Button>
    </Box>
}


const DataTable = ({rows}) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Zip Code</TableCell>
                        <TableCell align="left">Address</TableCell>
                        <TableCell align="right">Price</TableCell>
                        <TableCell align="right">Year Built</TableCell>
                        <TableCell align="right">Price/SF</TableCell>
                        <TableCell align="right">FAR</TableCell>
                        <TableCell align="right">AD Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.id}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell align="left">{row.zip_code}</TableCell>
                            <TableCell align="left">{row.address}</TableCell>
                            <TableCell align="right">{row.price}</TableCell>
                            <TableCell align="right">{row.year_built || 'N/A'}</TableCell>
                            <TableCell align="right">{row.price_per_sf || 'N/A'}</TableCell>
                            <TableCell align="right">{row.far || 'N/A'}</TableCell>
                            <TableCell align="right">
                                <a target="_blank"
                                   href={`https://www.loopnet.com/Listing/${row.id}`}
                                >Active</a>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};


export default withDataFetchAndTitle(SFOfficesTable, 'loopnet/sf_offices_v2', 'Loopnet - SF Offices')