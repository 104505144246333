import React from "react";
import AppAnnieGraphsViewer from "../app_annie/app_annie_graphs_viewer/AppAnnieGraphsViewer";
import Typography from "@mui/material/Typography";
import TimeLineViewer from "./generic_views/TimeLineViewer";


const StackedViewer = ({data: {content, meta}}) => {

    return <>
        <MetaView meta={meta}/>
        <div>
            {content.map(item => <DataView viewData={item} key={item.view_name}/>)}
        </div>

    </>
}

const MetaView = ({meta}) => {
    return <Typography
        sx={{marginBottom: '25px'}}
    >Export Date: {meta['export_date']}</Typography>
}


const DataView = ({viewData: {view_name, data, params}}) => {
    switch (view_name) {
        case 'app_annie':
            return <AppAnnieGraphsViewer data={data}/>
        case 'timeline':
            return <TimeLineViewer data={data} params={params}/>
        default:
            return <div>
                Unknown view: {view_name}
            </div>
    }
}


export default StackedViewer