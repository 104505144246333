import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data.reducer";
import {sortRegions} from "../SRSForwardGraphs/regions_selector/regions_sorter";
import DistributionPerMonth from "./DistributionPerMonth";
import {convertDistributionData} from "./distributionData.helper";
import Paper from "@mui/material/Paper";

let path = 'flight_data/srs_routes';

const SRSRoutesPage = () => {
    let data = useSelector(state => state.flightData.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - SRS Routes
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Tables/>}
    </>
}


const Tables = () => {
    let data = useSelector(state => state.flightData.data[path])
    let comp2019 = data['comp_2019'];
    let monthlyDist = data['monthly_dist']
    let sortedRegions = sortRegions([{data: monthlyDist}])
    let regionRank = {
        'All - All': -1
    }
    sortedRegions.forEach((region, i) => (regionRank[region] = i))

    let comp2019Regions = sortRegions([{data: comp2019}]).sort((regionLeft, regionRight) => regionRank[regionLeft] - regionRank[regionRight])


    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <Paper sx={
            {
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 500,
            }
        }>
            <Typography variant="h5" color="inherit" component="div">
                SRS Routes Percentage of 2019
            </Typography>
            <DistributionPerMonth data={convertDistributionData(comp2019, comp2019Regions)}/>
        </Paper>
        <Paper sx={
            {
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 500,
                marginTop: '50px',
            }
        }>
            <Typography variant="h5" color="inherit" component="div">
                SRS Routes Distribution per Month
            </Typography>
            <DistributionPerMonth data={convertDistributionData(monthlyDist)}/>
        </Paper>
    </Container>
}


export default SRSRoutesPage
