import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {fetchDateData1, fetchDateData2, fetchDates, setDate1, setDate2} from "../../../../store/gitlab/gitlabPlanning.reducer";
import DateSelector from "./date_selector/DateSelector";
import GitlabPlanGraph from "./planned_graph/GitlabPlanGraph";
import {convertData} from "./planned_graph/GraphData.helpers";


const GitlabPlanningPage = () => {
    const status = useSelector(state => state.gitlabPlanning.status)
    const dispatch = useDispatch()


    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchDates())
        }
    }, [dispatch, status])


    return <div>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>

            <Typography variant="h4" color="inherit" component="div">
                GitLab - Category Planning
            </Typography>
        </Container>
        {status === 'succeeded' ?
            <DataComp/> :
            <LoadingSpinnerComponent/>
        }
    </div>
}


const DataComp = () => {
    const dates = useSelector(state => state.gitlabPlanning.dates)
    const date1 = useSelector(state => state.gitlabPlanning.date1)
    const date2 = useSelector(state => state.gitlabPlanning.date2)
    const dispatch = useDispatch()
    const dateData1 = useSelector(state => state.gitlabPlanning.dateData1)
    const dateData2 = useSelector(state => state.gitlabPlanning.dateData2)
    const dateData1Status = useSelector(state => state.gitlabPlanning.date1Status)
    const dateData2Status = useSelector(state => state.gitlabPlanning.date2Status)

    useEffect(() => {
        if (date1) {
            dispatch(fetchDateData1(date1))
        }
    }, [dispatch, date1])

    useEffect(() => {
        if (date2) {
            dispatch(fetchDateData2(date2))
        }
    }, [dispatch, date2])

    const handleSetDate1 = (date) => {
        dispatch(setDate1(date));
    }
    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
        <DateSelector dates={dates} currentDate={date1}
                      setDate={handleSetDate1}/>
        <GitlabPlanGraph data={convertData(dateData1)} loading={dateData1Status !== 'succeeded'}/>
        <DateSelector dates={dates} currentDate={date2}
                      setDate={date => dispatch(setDate2(date))}/>
        <GitlabPlanGraph data={convertData(dateData2)} loading={dateData2Status !== 'succeeded'}/>
    </Container>
}


export default GitlabPlanningPage