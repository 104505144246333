import React from "react";
import {capY, convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import MultiLineGraphComponent from "../../../components/Plots/MultiLinePlot/GraphComponent";
import moment from "moment/moment";
import {noFormat} from "../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";


export const YearGraph = ({
                              series, title,
                              format = (v) => parseInt(moment(v).format("YYYY")) - 1,
                              minY = -Infinity,
                              maxY = Infinity,
                              yFormatFun = noFormat,
                              colors
                          }) => {
    return <MultiLineGraphComponent
        data={Object.keys(series).map(key => ({
            id: key,
            data: capY(convert(series[key]), maxY, yFormatFun, yFormatFun)
        }))}
        title={title}
        xaxis={``}
        axisBottomParams={{
            format: format,
            legend: 'year',
            legendOffset: -12,
        }
        }
        pointSymbol={MyCustomPoint}
        colors={colors}
    />
}

export const MyCustomPoint = (invisibleLines) => (props) => {
    const {x, y, size, color, datum} = props

    if (invisibleLines[datum.id]) {
        return null
    }

    if (datum && datum.isModified) {
        // Drawing an example triangle for modified points
        return (
            <circle cx={x} cy={y} r={8} fill={color}/>
        )
    }
    // Default circle for unmodified points
    return (
        <circle cx={x} cy={y} r={size / 2} fill={color}/>
    )
}