import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {roundNumber} from "../../../../common/data_helpers";
import CagrsTable from "./CagrsTable";


let EqualWeightsGraphs = ({data}) => {
    let sectionData = data['data']

    return <>
        <CagrsTable cagrsRows={sectionData.map(({meta, stock_performance, cagrs, inception_date, calc_start_date}) => {
            return {
                ticker: meta['ticker'],
                ...cagrs,
                inceptionDate: inception_date,
                calcStartDate: calc_start_date,
            }
        })}/>
        {sectionData.map(({meta, stock_performance, cagrs}) => <CompanyGraph
            meta={meta} stockPerformance={stock_performance} cagrs={cagrs}
        />)}
    </>
}


let CompanyGraph = ({meta, stockPerformance, cagrs}) => {
    return <Box>
        {/*<Typography variant="h6"> {meta['ticker']} - {meta['description']}</Typography>*/}
        <TimeLinePlotWithSettings data={stockPerformance}
                                  title={`${meta['ticker']} - ${meta['description']}`}
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
        />
        <Typography sx={{marginBottom: '45px', marginTop: '-40px'}}>
            CAGRS: Actual: {roundNumber(cagrs['Actual'], 1)}%, Equal Weights: {roundNumber(cagrs['Equal Weights'], 1)}%,
            Holdings Model: {roundNumber(cagrs['Holdings Model'], 1)}%
        </Typography>
        {/*<CAGRTable cagrs={cagrs}/>*/}
    </Box>
}

let CAGRTable = ({cagrs}) => {
    return <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
            <TableRow>
                <TableCell>Actual</TableCell>
                <TableCell>Equal Weights</TableCell>
                <TableCell>Holdings Model</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
            >
                <TableCell align="right">{cagrs['Actual']}</TableCell>
                <TableCell align="right">{cagrs['Equal Weights']}</TableCell>
                <TableCell align="right">{cagrs['Holdings Model']}</TableCell>
            </TableRow>
        </TableBody>
    </Table>
}


export default EqualWeightsGraphs