import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/amzn.reducer";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import TimeBarWithPrint from "../../../../components/Plots/TimeBar/TimeBar";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import AreaPlot from "../../../../components/Plots/AreaPlot/AreaPlot";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {percFormat} from "../../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";


let path = 'amzn/ce_sales';

const AmznCESalesPage = () => {
    let data = useSelector(state => state.amzn.data)
    let dispatch = useDispatch()


    useEffect(() => {
        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                AMZN - CE Sales Competitors
            </Typography>
        </Container>
        <Container>
            {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.amzn.data[path])
    return <>

        <AreaPlot
            data={data['market_share']}
            title={"Market Share"}
        />

        <TimeLinePlotWithSettings
            data={data['yoy']}
            title={`YoY Growth`}
            yFormatFun={percFormat}
            defaultMaxY={120}
            defaultOrderOfMagnitude={["", 1]}
            otherLineProps={{curve: 'linear'}}

        />
    </>
}

export default AmznCESalesPage
