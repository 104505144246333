import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/dataApi.reducer";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import {millionFormat, noFormat, thousandsFormat} from "../../../../components/Plots/TimeLinePlot/timeLinePlotHelpers";
import {filterNestedDictWithOffset} from "../../../../common/data_helpers";


let path = 'productivity/semrush2';

const ProductivitySemrushPage = () => {
    let data = useSelector(state => state.dataApi.data[path])
    let dispatch = useDispatch()

    //
    useEffect(() => {
        if (data === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Productivity - Semrush
            </Typography>
        </Container>
        <Container>
            {data === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
        </Container>
    </>
}

const Graphs = () => {
    let data = useSelector(state => state.dataApi.data[path])

    console.log(data['traffic'])
    return <div>
        {/*{JSON.stringify(data)}*/}
        <TimeLinePlotWithSettings data={transformAtlassian(data['visits'])} title='Total Visitors [millions]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={millionFormat}/>
        <TimeLinePlotWithSettings data={transformAtlassian(data['users'])} title='Unique Visitors [millions]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={millionFormat}/>
        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(transformAtlassian(data['time_on_site']), 36)}
                                  title='Average Duration per Visit  [seconds]'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={(value) => `${Math.round(value)}s`}/>
        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(transformAtlassian(data['traffic']), 48)}
                                  title='Paid traffic'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={thousandsFormat}/>

        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(transformAtlassian(data['trafficCost']), 60)}
                                  title='Domain wise Paid Traffic Cost per month (USD)'
                                  yFormatFun={thousandsFormat}
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
        />


        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(transformAtlassian(data['domainkw_cpc']), 24)}
                                  title='Domain wise Cost per Click (USD)'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={value => value} defaultMaxY={25}/>

        <TimeLinePlotWithSettings
            data={filterNestedDictWithOffset(transformAtlassian(data['domainkw_total_keywords']), 24)}
            title='Domain wise Number of Keywords'
            defaultSmoothing={3}
            smoothingUnit={"months"}
            yFormatFun={noFormat}/>

        <TimeLinePlotWithSettings data={filterNestedDictWithOffset(transformAtlassian(data['domainkw_mean_spend']), 24)}
                                  title='Domain wise Average Spend per Keyword (USD)'
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
                                  yFormatFun={value => value}/>


        {/*<Graph data={transformAtlassian(data['domainkw_total_spend'])} title='DW Keywords total spend'*/}
        {/*       yFormatFun={thousandsFormat}/>*/}


        {/*<Graph data={transformAtlassian(data['cons_keywords_spend_history'])} title='DW Keywords count'*/}
        {/*       yFormatFun={noFormat}/>*/}

    </div>
}

function transformAtlassian(inputObj) {
    // Check if the specified key exists in the input object
    if (!inputObj.hasOwnProperty('atlassian.com(with atlassian.net)')) {
        return inputObj;
    }

    const newValue = inputObj['atlassian.com(with atlassian.net)'];
    const newObject = {};

    // Iterate through the keys of the input object
    for (const key in inputObj) {
        if (inputObj.hasOwnProperty(key)) {
            if (key === 'atlassian.com(with atlassian.net)') {

            } else if (key === 'atlassian.com') {
                newObject['atlassian.com*'] = newValue;
            } else {
                // Copy other keys as-is
                newObject[key] = inputObj[key];
            }
        }
    }

    return newObject;
}



export default ProductivitySemrushPage