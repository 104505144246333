import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {updateSelections} from "../../../../../store/flight_data/flight_data_srs_forward.reducer";
import {DataGrid} from '@mui/x-data-grid';

let DateSelector = () => {
    const dates = useSelector(state => state.flightDataSRSForward.dates)
    const dateSelections = useSelector(state => state.flightDataSRSForward.dateSelections)
    let dispatch = useDispatch()


    const columns = [
        {field: 'date', headerName: 'Dates', width: '100'},
    ]

    let rows = dates.map(date => ({date: date, id: date}))

    return (
        <div style={{height: 400, width: '100%'}}>
            <DataGrid
                label="Region"
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                // disableSelectionOnClick
                onSelectionModelChange={(newSelectionModel) => {
                    dispatch(updateSelections(newSelectionModel))
                }}
                selectionModel={dateSelections}
            />
        </div>
    );

}

export default DateSelector