import React from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch, useSelector} from "react-redux";
import {setRegion} from "../../../../../store/flight_data/flight_data_srs_forward.reducer";


let RegionsSelector = () => {
    const region = useSelector(state => state.flightDataSRSForward.region)
    const regions = useSelector(state => state.flightDataSRSForward.regions)
    const dispatch = useDispatch()

    let handleChange = (change) => {
        dispatch(setRegion(change.target.value))
    }

    return <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Region</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={region}
            label="Region"
            onChange={handleChange}
        >
            {
                regions.map(value => {
                    return <MenuItem key={value} value={value}>{value}</MenuItem>
                })
            }
        </Select>
    </FormControl>
}


export default RegionsSelector