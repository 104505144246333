import withDataFetchAndTitle from "../../components/DataLoadPage";
import SemrushGraphsComponent from "../../components/semrush/SemrushGraphsComponent";

const CostarSemrush = ({data}) => {
    return <>
        <SemrushGraphsComponent data={data}/>
    </>

}

export default withDataFetchAndTitle(SemrushGraphsComponent(), 'costar/semrush', 'Costar - Semrush')
