import * as React from 'react';
import {useState} from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {ReactComponent as GitlabIcon} from './gitlab/GitlabIcon.svg';
import {ReactComponent as GithubIcon} from '../components/logos/github.svg';
import {ReactComponent as GoogleTrendsIcon} from '../components/google_trends/Google Trends Icon Logo.svg';
import {ReactComponent as StackoverflowIcon} from '../components/stackoverflow/logo-stackoverflow.svg';
import {ReactComponent as AmazonIcon} from './amzn/logo.svg';
import {ReactComponent as DatadogIcon} from './datadog/logo.svg';
import {ReactComponent as ZoominfoIcon} from './zoominfo/logo.svg';
import {ReactComponent as IndeedComIcon} from '../components/indeed_com/indeed-svgrepo-com.svg';
import {ReactComponent as BillDotComIcon} from './billdotocom/logo.svg';
import {ReactComponent as AmazonShopifyIcon} from './amzn/shopify-logo.svg';
import {ReactComponent as ProcoreIcon} from './procore/logo.svg';
import {ReactComponent as AutodeskIcon} from './autodesk/autodesk-svgrepo-com.svg';
import {ReactComponent as DatabaseIcon} from '../components/logos/database.svg';
import {ReactComponent as AppAnnieIcon} from '../components/logos/data-ai-header-logo.svg';
import {ReactComponent as MondayDotComIcon} from './productivity/monday-icon-svgrepo-com.svg';
import {ReactComponent as GuidewireIcon} from './guidewire/logo.svg';
import {ReactComponent as ETFIcon} from './etfs/logo.svg';
import {ReactComponent as IBKRIcon} from './ibkr/ibkr_logo.svg';
import {ReactComponent as SNOWIcon} from './snowflake/snoflake_icon.svg';
import {ReactComponent as MongoIcon} from './mongodb/mongo_icon.svg';
import {ReactComponent as ShopifyIcon} from './shopify/shopify_icon.svg';
import {ReactComponent as CWANIcon} from './cwan/cwan_logo.svg';
import {ReactComponent as CPNGIcon} from './cpng/CPNG.svg';
import {ReactComponent as TDGIcon} from './flight_data/TDG.svg';
import {ReactComponent as LLMSIcon} from './llms/ai-svgrepo-com.svg';
import {ReactComponent as TeslaIcon} from './tesla/Tesla_Motors.svg';
import {ReactComponent as CostarIcon} from './costar/costar-realty-information-inc-logo-vector.svg';
import {ReactComponent as FolioIcon} from './folio/appfolio-v2-svgrepo-com.svg';
import {ReactComponent as DexcomIcon} from './dexcom/dexcom-g6-svgrepo-com.svg';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ScaleIcon from '@mui/icons-material/Scale';
import {ReactComponent as SpyIcon} from './etfs/spy-svgrepo-com.svg';

import TimelineIcon from '@mui/icons-material/Timeline';
import {Collapse} from "@mui/material";
import List from "@mui/material/List";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import FastForwardIcon from '@mui/icons-material/FastForward';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import RadarIcon from '@mui/icons-material/Radar';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import HistoryIcon from '@mui/icons-material/History';
import ListIcon from '@mui/icons-material/List';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import VisibilityIcon from '@mui/icons-material/Visibility';


import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ConstructionIcon from '@mui/icons-material/Construction';
import AbcIcon from '@mui/icons-material/Abc';

import DDOGOpenTelemetryIcon from './datadog/open_telemetry.png';


const DDOGOpenTelemetryLogoComponent = () => {
    return <img src={DDOGOpenTelemetryIcon} alt="Open Telemetry Logo" style={{width: '25px', height: 'auto'}}/>
}


export const MainListItems = ({navigate}) => {
    return <React.Fragment>

        <FoldableSection
            title='TDG'
            Icon={TDGIcon}
            navigate={navigate}
            items={[
                {title: 'SRS Forward', url: 'flight_data/srs_forward_graphs', Icon: FastForwardIcon},
                {title: 'Cirium Flights', url: 'flight_data/cirium_flights', Icon: SsidChartIcon},
                {title: 'Regional Flights', url: 'flight_data/regional_flights', Icon: SsidChartIcon},
                {title: 'SRS Routes', url: 'flight_data/srs_routes', Icon: TableChartIcon},
                {title: 'UBS', url: 'flight_data/ubs', Icon: SsidChartIcon},
                {title: 'Flight Radar', url: 'flight_data/flight_radar', Icon: RadarIcon},
                {title: 'TSA', url: 'flight_data/tsa', Icon: LocalPoliceIcon},
                {title: 'ASM History', url: 'flight_data/asm', Icon: HistoryIcon},
                {title: 'Fleet Age', url: 'flight_data/fleet_age', Icon: CalendarMonthIcon},
                {title: 'Orders', url: 'flight_data/orders', Icon: ShoppingCartCheckoutIcon},
                {title: 'First Flights', url: 'flight_data/first_flights', Icon: FlightTakeoffIcon},
            ]}
        />

        <FoldableSection
            title='BILL'
            Icon={BillDotComIcon}
            navigate={navigate}
            items={[
                {title: 'Semrush', url: 'billdotcom/semrush', Icon: SsidChartIcon},
                {title: 'Divvy AppAnnie', url: 'billdotcom/divvy', Icon: AppAnnieIcon},
                // {title: 'Exports', url: 'exports', Icon: ListIcon},
            ]}
        />

        <FoldableSection
            title='GTLB'
            Icon={GitlabIcon}
            navigate={navigate}
            items={[
                {title: 'Sales Team', url: 'gitlab/sales', Icon: AttachMoneyIcon},
                {title: 'Category planning', url: 'gitlab/categories', Icon: TimelineIcon},
            ]}
        />

        <FoldableSection
            title='AMZN'
            Icon={AmazonIcon}
            navigate={navigate}
            items={[
                {title: 'CE Weekly Sales', url: 'amzn/ce_sales', Icon: TimelineIcon},
                {title: 'CE Competitors', url: 'amzn/ce_competitors', Icon: TimelineIcon},
                {title: 'Buy With Prime', url: 'amzn/buy_with_prime', Icon: AmazonShopifyIcon},
            ]}
        />

        <FoldableSection
            title='DDOG'
            Icon={DatadogIcon}
            navigate={navigate}
            items={[
                {title: 'Workforce', url: 'datadog/workforce', Icon: GroupIcon},
                {title: 'HG Insights', url: 'datadog/hginsights', Icon: InsightsIcon},
                {title: 'Semrush', url: 'datadog/semrush', Icon: SsidChartIcon},
                {title: 'G2 - Buyer Intent', url: 'datadog/g2_buyer_intent', Icon: SsidChartIcon},
                {title: 'CNCF observability', url: 'datadog/cncf_observability', Icon: VisibilityIcon},
                {title: 'Open Telemetry', url: 'datadog/open_telemetry', Icon: DDOGOpenTelemetryLogoComponent},
                {title: 'Security Sales', url: 'datadog/security_sales', Icon: LocalPoliceIcon},
            ]}
        />

        <FoldableSection
            title='ZI'
            Icon={ZoominfoIcon}
            navigate={navigate}
            items={[
                {title: 'Workforce', url: 'zoominfo/workforce', Icon: GroupIcon},
            ]}
        />

        <FoldableSection
            title='PCOR'
            Icon={ProcoreIcon}
            navigate={navigate}
            items={[
                {title: 'AppAnnie', url: 'procore/app_annie', Icon: AppAnnieIcon},
                {title: 'AppAnnie ADSK', url: 'procore/app_annie_adsk', Icon: AppAnnieIcon},
                {title: 'Workforce', url: 'procore/workforce', Icon: GroupIcon},
            ]}
        />

        <FoldableSection
            title='ADSK'
            Icon={AutodeskIcon}
            navigate={navigate}
            items={[
                {title: 'G2 - Buyer Intent', url: 'autodesk/g2_buyer_intent', Icon: SsidChartIcon},
                {title: 'G2 - Reviews', url: 'autodesk/g2', Icon: SsidChartIcon},
                {title: 'Semrush', url: 'autodesk/semrush', Icon: SsidChartIcon},

            ]}
        />

        {/*<FoldableSection*/}
        {/*    title='Focus'*/}
        {/*    Icon={FilterTiltShiftIcon}*/}
        {/*    navigate={navigate}*/}
        {/*    items={[*/}
        {/*        {title: 'Files', url: 'focus/files', Icon: ListIcon},*/}
        {/*        {title: 'Actions View', url: 'focus/actions', Icon: PageviewIcon},*/}
        {/*        {title: 'Upload', url: 'focus/upload', Icon: CloudUploadIcon},*/}
        {/*    ]}*/}
        {/*/>*/}


        <FoldableSection
            title='GWRE'
            Icon={GuidewireIcon}
            navigate={navigate}
            items={[
                {title: 'Workforce', url: 'guidewire/workforce', Icon: GroupIcon},
            ]}
        />


        <FoldableSection
            title='Shopify'
            Icon={ShopifyIcon}
            navigate={navigate}
            items={[
                {title: 'Built with', url: 'shopify/builtwith', Icon: ConstructionIcon},
                {title: 'AppAnnie', url: 'shopify/app_annie', Icon: AppAnnieIcon},
                {title: 'PublicWWW', url: 'shopify/public_www', Icon: ConstructionIcon},

            ]}
        />


        <FoldableSection
            title='DXCM'
            Icon={DexcomIcon}
            navigate={navigate}
            items={[
                {title: 'AppAnnie', url: 'dexcom/app_annie', Icon: AppAnnieIcon},
                {title: 'Stelo AppAnnie', url: 'dexcom/stelo_app_annie', Icon: AppAnnieIcon},
            ]}
        />

        <FoldableSection
            title='Costar'
            Icon={CostarIcon}
            navigate={navigate}
            items={[
                // {title: 'AppAnnie', url: 'dexcom/app_annie', Icon: AppAnnieIcon},
                {title: 'Semrush', url: 'costar/semrush', Icon: SsidChartIcon},
            ]}
        />
        {/*<FoldableSection*/}
        {/*    title='CWAN'*/}
        {/*    Icon={CWANIcon}*/}
        {/*    navigate={navigate}*/}
        {/*    items={[*/}
        {/*        {title: 'Workforce', url: 'cwan/workforce', Icon: GroupIcon},*/}
        {/*    ]}*/}
        {/*/>*/}
    </React.Fragment>
}

const FoldableSection = ({title, Icon, navigate, items}) => {
    let [open, setOpen] = useState(false)

    return <>
        <ListItemButton onClick={() => setOpen(!open)}>
            <ListItemIcon>
                <Icon style={{height: 25, width: 24}}/>
            </ListItemIcon>
            <ListItemText primary={title}/>
            {open ? <ExpandLess/> : <ExpandMore/>}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                {items.map(({title, url, Icon}) =>
                    <ListItemButton sx={{pl: 4}} onClick={() => navigate(url)}>
                        <ListItemIcon>
                            <Icon style={{height: 25, width: 24}}/>
                        </ListItemIcon>
                        <ListItemText primary={title}/>
                    </ListItemButton>
                )}


            </List>
        </Collapse>
    </>
}

export const SecondaryListItems = ({navigate}) => {
    return <React.Fragment>

        <FoldableSection
            title='MNDY'
            Icon={MondayDotComIcon}
            navigate={navigate}
            items={[
                // {title: 'Experiments', url: 'productivity/experiments', Icon: SsidChartIcon},
                {title: 'AppAnnie', url: 'productivity/app_annie', Icon: SsidChartIcon},
                {title: 'Semrush', url: 'productivity/semrush', Icon: SsidChartIcon},
                {title: 'G2', url: 'productivity/g2', Icon: SsidChartIcon},
                {title: 'G2 - Buyer Intent', url: 'productivity/g2_buyer_intent', Icon: SsidChartIcon},
                // {title: 'Twitter', url: 'productivity/twitter', Icon: TwitterIcon},
                {title: 'Built with', url: 'productivity/builtwith', Icon: ConstructionIcon},
                {title: 'HG Insights', url: 'productivity/hginsights', Icon: InsightsIcon},
                {title: 'Workforce', url: 'productivity/workforce', Icon: GroupIcon},
                {title: 'Indeed', url: 'productivity/indeed', Icon: IndeedComIcon},
                {title: 'Acuity Linkedin', url: 'productivity/acuity_linkedin', Icon: GroupIcon},
                {title: 'Google Trends', url: 'productivity/google_trends', Icon: GoogleTrendsIcon},

            ]}
        />
        <FoldableSection
            title='ETFs'
            Icon={ETFIcon}
            navigate={navigate}
            items={[
                {title: 'Equal Weights', url: 'etf/equal_weights', Icon: ScaleIcon},
                {title: 'SPY', url: 'etf/spy', Icon: SpyIcon},
                // {title: 'CAGRs', url: 'etf/cagrs', Icon: TableViewIcon},
            ]}
        />
        <FoldableSection
            title='IBKR'
            Icon={IBKRIcon}
            navigate={navigate}
            items={[
                {title: 'AppAnnie', url: 'ibkr/app_annie', Icon: AppAnnieIcon},
                {title: 'AppAnnie Summary', url: 'ibkr/app_annie_summary', Icon: SsidChartIcon},
                {title: 'Semrush', url: 'ibkr/semrush', Icon: SsidChartIcon},
            ]}
        />
        <FoldableSection
            title='SNOW'
            Icon={SNOWIcon}
            navigate={navigate}
            items={[
                {title: 'Github Stars', url: 'snow/github_stars', Icon: GithubIcon},
                {title: 'DB Engines', url: 'snow/db_engines', Icon: DatabaseIcon},
                {title: 'HG Insights', url: 'snow/hginsights', Icon: InsightsIcon},
                {title: 'Indeed', url: 'snow/indeed', Icon: IndeedComIcon},

                // {title: 'Semrush', url: 'ibkr/semrush', Icon: SsidChartIcon},
            ]}
        />
        <FoldableSection
            title='Loopnet'
            Icon={LocationCityIcon}
            navigate={navigate}
            items={[
                {title: 'SF Offices', url: 'loopnet/sf_offices', Icon: TimelineIcon},
            ]}
        />
        <FoldableSection
            title='MongoDB'
            Icon={MongoIcon}
            navigate={navigate}
            items={[
                {title: 'Github Stars', url: 'mongodb/github_stars', Icon: GithubIcon},
                {title: 'DB Engines', url: 'mongodb/db_engines', Icon: DatabaseIcon},
                {title: 'Indeed', url: 'mongodb/indeed', Icon: IndeedComIcon},
                {title: 'Google Trends', url: 'mongodb/google_trends', Icon: GoogleTrendsIcon},
                {title: 'StackOverflow', url: 'mongodb/stackoverflow', Icon: StackoverflowIcon},
            ]}
        />

        <FoldableSection
            title='TSLA'
            Icon={TeslaIcon}
            navigate={navigate}
            items={[
                {title: 'AppAnnie', url: 'tesla/app_annie', Icon: AppAnnieIcon},
            ]}
        />

        <FoldableSection
            title='LLMs'
            Icon={LLMSIcon}
            navigate={navigate}
            items={[
                {title: 'App Annie', url: 'llms/app_annie', Icon: AppAnnieIcon},
                {title: 'Semrush', url: 'llms/semrush', Icon: SsidChartIcon},
            ]}
        />

        <FoldableSection
            title='CPNG'
            Icon={CPNGIcon}
            navigate={navigate}
            items={[
                {title: 'App Annie', url: 'cpng/app_annie', Icon: AppAnnieIcon},
                {title: 'Semrush', url: 'cpng/semrush', Icon: SsidChartIcon},
            ]}
        />


        <FoldableSection
            title='Folio'
            Icon={FolioIcon}
            navigate={navigate}
            items={[
                // {title: 'AppAnnie', url: 'dexcom/app_annie', Icon: AppAnnieIcon},
                {title: 'Semrush', url: 'folio/semrush', Icon: SsidChartIcon},
                {title: 'Semrush Folio Guard', url: 'folio/semrush_folio_guard', Icon: SsidChartIcon},
                {title: 'Built with', url: 'folio/builtwith', Icon: ConstructionIcon},
            ]}
        />

    </React.Fragment>
}


export const TertiaryListItems = ({navigate}) => {
    return <React.Fragment>

        <FoldableSection
            title='Other'
            Icon={MiscellaneousServicesIcon}
            navigate={navigate}
            items={[
                {title: 'Indeed', url: 'other/indeed', Icon: IndeedComIcon},
                {title: 'Acuity Linkedin', url: 'other/acuity_linkedin', Icon: GroupIcon},
            ]}
        />
    </React.Fragment>
}

export const OtherListItems = ({navigate}) => {
    return <React.Fragment>

        <FoldableSection
            title='Text Cruncher'
            Icon={AbcIcon}
            navigate={navigate}
            items={[
                {title: 'Procore v0', url: 'text_cruncher/procore', Icon: SsidChartIcon},
            ]}
        />

    </React.Fragment>
}