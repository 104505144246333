import React from "react";
import TimeLinePlotWithSettings from "../../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";
import Typography from "@mui/material/Typography";
import {roundNumber} from "../../../../common/data_helpers";
import HoldingsTable from "./HoldingsTable";


const ETFDetailsGraph = ({etfDetails: {etf, etf_name, details}}) => {
    let holdingsMeta = details['holdings_meta']
    return <div style={{marginTop: '50px'}}>
        <Typography variant={'h5'}>
            {etf} - {etf_name}
        </Typography>

        <TimeLinePlotWithSettings data={details['graph_data']}
            // title={`${meta['ticker']} - ${meta['description']}`}
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
        />
        <Typography sx={{marginBottom: '10px'}}>
            Inception: {details['tearsheet']['year_founded']}, CAGR SPY: {roundNumber(details['cagrs']['SPY'], 1)}%,
            CAGR {etf}: {roundNumber(details['cagrs'][etf], 1)}%
        </Typography>

        <HoldingsTable rowData={details['holdings']}/>

        <Typography sx={{marginTop: '10px'}}>
            Top 25 holdings make up {roundNumber(holdingsMeta['top_25'], 2)}% of equity. There are {holdingsMeta['n_active']} active holdings and {holdingsMeta['n_total']} holdings since inception
        </Typography>
    </div>
}


export default ETFDetailsGraph