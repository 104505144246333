import React from "react";
import Paper from "@mui/material/Paper";
import {CircularProgress} from "@mui/material";
import Container from "@mui/material/Container";


let LoadingSpinnerComponent = () => <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
    <Paper sx={
        {
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 440,
            justifyContent: 'center',
            alignItems: 'center'
        }
    }>
        <CircularProgress
            size="5rem"
            color="inherit"/>
    </Paper>
</Container>

export default LoadingSpinnerComponent