import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import Typography from "@mui/material/Typography";
import {styled} from "@mui/material/styles";


export const SimpleDisplayTable = ({data, title, timePeriods, numberFormat}) => {

    let lastTimePeriod = timePeriods[timePeriods.length - 1];

    const keys = Object.keys(data);

    keys.sort((keyA, keyB) => {
        return (data[keyB][lastTimePeriod] || -Infinity) - (data[keyA][lastTimePeriod] || -Infinity)
    })


    let styling = {fontSize: '0.9rem', padding: '3px'}
    const StyledTableRow = styled(TableRow)(({theme}) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
        '&:hover': {
            backgroundColor: theme.palette.action.selected,
        },
    }));

    return (

        <TableContainer component={Paper}>
            <Typography>{title}</Typography>
            <Table>
                <TableHead style={{borderBottom: '2px solid #000'}}>
                    <TableRow>
                        <TableCell sx={styling}>App</TableCell>
                        {timePeriods.map((column, index) => (
                            <TableCell key={column} sx={styling}>
                                {column}
                            </TableCell>
                        ))}
                        <TableCell sx={styling}>App</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {keys.map((key) => (
                        <StyledTableRow key={key}>
                            <TableCell component="th" scope="row" sx={styling}>
                                {key}
                            </TableCell>
                            {timePeriods.map((column) => (
                                <TableCell key={column} sx={styling}>
                                    {data[key][column] ? numberFormat(data[key][column]) : ""}
                                </TableCell>
                            ))}
                            <TableCell component="th" scope="row" sx={styling}>
                                {key}
                            </TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
