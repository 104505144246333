import React from "react";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import GraphComponent from "../../../components/Plots/GraphComponent/GraphComponent";
import {convert} from "../../../components/Plots/GraphComponent/GraphComponent.helpers";
import Accordion from "@mui/material/Accordion";

import MultiLineGraphComponent from "../../../components/Plots/MultiLinePlot/GraphComponent";

const WorkforcePage = ({data}) => (
    <>
        {Object.keys(data).map(key => {
            if (key !== 'all') {
                return <CompanyAccordion key={key} companyName={key} companyData={data[key]}/>;
            }
            return null;
        })}
        <AllAccordion data={data}/>
    </>
)

function CompanyAccordion({companyName, companyData}) {
    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>{companyName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <GraphComponent
                data={convert(companyData.employee_counts_raw)}
                title={`${companyName} - Linkedin number of employees`}
                xaxis={'# of employees (Linkedin)'}
            />
            <GraphComponent
                data={convert(companyData.employee_counts_yoy)}
                title={`${companyName} - Linkedin number of employees YoY Growth`}
                xaxis={'# of employees (Linkedin)'}
            />
            <GraphComponent
                data={convert(companyData.employee_counts_mom)}
                title={`${companyName} - Linkedin number of employees MoM Growth`}
                xaxis={'# of employees (Linkedin)'}
            />

        </AccordionDetails>
    </Accordion>
}


function AllAccordion({data}) {

    let colors = Object.keys(data).length <= 6 ? {scheme: 'nivo'} : {scheme: 'category10'};

    return <Accordion TransitionProps={{unmountOnExit: true}}>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <Typography>All</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <MultiLineGraphComponent
                data={Object.keys(data).map(key => ({
                    id: key,
                    data: convert(data[key]['employee_counts_monthly'])
                }))}
                title={`Linkedin number of employees`}
                xaxis={``}
                colors={colors}
            />

            <MultiLineGraphComponent
                data={Object.keys(data).map(key => ({
                    id: key,
                    data: convert(data[key]['employee_counts_yoy'])
                }))}
                title={`Linkedin number of employees YoY growth [%]`}
                xaxis={``}
                colors={colors}
            />

            <MultiLineGraphComponent
                data={Object.keys(data).map(key => ({
                    id: key,
                    data: convert(data[key]['employee_counts_mom'])
                }))}
                title={`Linkedin number of employees MoM growth [%]`}
                xaxis={``}
                colors={colors}
            />

        </AccordionDetails>
    </Accordion>
}


export default WorkforcePage
