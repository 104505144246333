import React, {useState} from "react";
import {getOrderOfMagnitude, getYAxisFormatFun, mapObjectValues, trailingAverage} from "../../../common/data_helpers";
import {addId, capY, convert, findLargestY, findSmallestY} from "../GraphComponent/GraphComponent.helpers";
import TimeLinePlot from "./TimeLinePlot";
import {MyCustomPoint} from "../../../pages/components/hginsights/CommonGraphs";

const TimeLinePlotWithSettings = ({
                                      data,
                                      title,
                                      yFormatFun,
                                      defaultMaxY,
                                      defaultMinY,
                                      defaultSmoothing,
                                      smoothingUnit,
                                      defaultOrderOfMagnitude = undefined,
                                      colorScheme = 'category10',
                                      otherLineProps = {}
                                  }) => {
    const convertedData = []

    const [maxY, setMaxY] = useState(defaultMaxY)
    const [minY, setMinY] = useState(defaultMinY)

    const [smoothing, setSmoothing] = useState(defaultSmoothing)

    const [magLabel, magOrder] = defaultOrderOfMagnitude === undefined ? getOrderOfMagnitude(findLargestY(data)) : defaultOrderOfMagnitude

    let [invisibleLines, setInvisibleLines] = useState(Object.fromEntries(
        Object.keys(data).map(key => [key, false])
    ))

    let smoothedData = mapObjectValues(data, trailingAverage(smoothing))

    let visibleData = Object.keys(data).filter(key => invisibleLines[key] === false).reduce((obj, key) => (obj[key] = smoothedData[key], obj), {});
    let invisibleCount = Object.values(invisibleLines).filter(value => value === true).length;
    const maxVisibleY = findLargestY(visibleData);
    const minVisibleY = findSmallestY(visibleData);
    const fallBackMinY = minVisibleY < 0 ? minVisibleY : undefined

    const finalYFomatFun = yFormatFun ? yFormatFun : getYAxisFormatFun(magOrder)

    Object.keys(smoothedData).forEach(key => {
        convertedData.push(
            {
                id: key, data:
                    capY(addId(convert(smoothedData[key]), key), maxY * magOrder, minY * magOrder, finalYFomatFun)
            },
        )
    })


    return <><TimeLinePlot
        data={convertedData}
        title={title}
        xaxis={``}
        lineProps={{
            // yFormat: " >-d",
            yFormat: finalYFomatFun,
            colors: {scheme: colorScheme},
            axisLeft: {
                format: finalYFomatFun,
            },
            curve: 'cardinal',
            pointSymbol: MyCustomPoint(invisibleLines),
            pointSize: 0,
            legends: [],
            yScale: {
                type: 'linear',
                stacked: false,
                min: minY !== undefined ? minY * magOrder : fallBackMinY,
                max: invisibleCount === 0 || maxY !== undefined ? undefined : maxVisibleY
            },
            ...otherLineProps
        }}


        // enableArea: true

        settings={{
            maxY: [maxY, setMaxY],
            minY: [minY, setMinY],
            magnitude: [magLabel, magOrder],
            visibleLines: [invisibleLines, setInvisibleLines],
            smoothing: [smoothing, setSmoothing],
            smoothingUnit: smoothingUnit,
        }}
        invisibleLines={invisibleLines}

    />

    </>
}

export default TimeLinePlotWithSettings