import React from "react";
import {SimpleDisplayTable} from "../table/SimpleDisplayTable";
import {getFilenameFromUrl, getNumberRounder, getUniqueKeysOfNestedDicts} from "../../../common/data_helpers";
import TimeLinePlotWithSettings from "../../../components/Plots/TimeLinePlot/TimeLinePlotWithSettings";


const GoogleTrendsDisplayComponent = ({data: {trends_data}}) => {
    // const shareUrl = meta['share_url']
    return <div>
        {/*<a href={shareUrl} target={"_blank"}>{getFilenameFromUrl(shareUrl)}</a>*/}
        {/*<div style={{height: '25px'}}></div>*/}

        <SimpleDisplayTable data={trends_data}
                            timePeriods={getUniqueKeysOfNestedDicts(trends_data).slice(-16)}
                            numberFormat={getNumberRounder(0)}
                            title={"Google Trends History"}
        />


        <div style={{height: '50px'}}></div>

        <TimeLinePlotWithSettings data={trends_data} title={'Google Trends History'} yFormat=" >-d" defaultMaxY={125}
                                  defaultSmoothing={3}
                                  smoothingUnit={"months"}
        />


    </div>
}

export default GoogleTrendsDisplayComponent