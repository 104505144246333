import React from "react";
import {SimpleDisplayTable} from "../table/SimpleDisplayTable";
import {
    extractSubDict,
    getFilenameFromUrl,
    getNumberRounder,
    getUniqueKeysOfNestedDicts
} from "../../../common/data_helpers";


const AcuityLinkedinPage = ({acuityLinkedinData: {data, meta}}) => {
    const shareUrl = meta['share_url']
    return <div>
        <a href={shareUrl} target={"_blank"}>{getFilenameFromUrl(shareUrl)}</a>
        <div style={{height: '25px'}}></div>


        {Object.keys(data).map((key, index) => (
            <>
                <SimpleDisplayTable data={data[key]['headcount']}
                                    timePeriods={getUniqueKeysOfNestedDicts(data[key]['headcount']).slice(-16)}
                                    numberFormat={getNumberRounder(0)}
                                    title={key}
                />
                {/*<div style={{height: '25px'}}></div>*/}
                {/*<SimpleDisplayTable data={data[key]['headcount_yoy']}*/}
                {/*                    timePeriods={getUniqueKeysOfNestedDicts(data[key]['headcount_yoy']).slice(-16)}*/}
                {/*                    numberFormat={getNumberRounder(0)}*/}
                {/*                    title={`${key} YoY`}*/}
                {/*/>*/}
                {/*<div style={{height: '25px'}}></div>*/}
            </>

        ))}

        <SimpleDisplayTable data={
            extractSubDict(data, 'tenure')
        }
                            timePeriods={getUniqueKeysOfNestedDicts(extractSubDict(data, 'tenure')).slice(-16)}
                            numberFormat={getNumberRounder(0)}
                            title={"Tenure - median years"}
        />
    </div>


}

export default AcuityLinkedinPage