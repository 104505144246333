import React from "react";
import IndeedComPage from "../../components/indeed_com/IndeedComPage";
import withDataFetchAndTitle from "../../components/DataLoadPage";


let path = 'mongodb/indeed_v2';

const MongoIndeed = ({data}) => {

    return <>
        <IndeedComPage indeedData={data}/>
    </>
}


export default withDataFetchAndTitle(MongoIndeed, path, 'MongoDB - Indeed.com job counts')