import React, {useEffect} from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import LoadingSpinnerComponent from "../../../../components/LoadingSpinnerComponent/LoadingSpinnerComponent";
import {useDispatch, useSelector} from "react-redux";
import {fetchData} from "../../../../store/flight_data/flight_data.reducer";
import GraphComponent from "../../../../components/Plots/MultiLinePlot/GraphComponent";
import {convert} from "../../../../components/Plots/GraphComponent/GraphComponent.helpers";
import {DashedSolidLine} from "../EventsDashboard/GraphHelper";

let path = 'flight_data/fleet_age';


const FleetAgePage = () => {
    let data = useSelector(state => state.flightData.data)
    let dispatch = useDispatch()

    useEffect(() => {

        if (data[path] === undefined) {
            dispatch(fetchData({path}))
        }
    }, [dispatch, data])

    return <>
        <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>
            <Typography variant="h4" color="inherit" component="div">
                Flight Data - Fleet Age
            </Typography>
        </Container>
        {data[path] === undefined ? <LoadingSpinnerComponent/> : <Graphs/>}
    </>
}


const Graphs = () => {
    let data = useSelector(state => state.flightData.data[path])
    return <Container maxWidth="lg" sx={{mt: 4, mb: 4}}>

        <GraphComponent
            title={"Out of warranty cycles - yoy (7 day mean)"}
            data={
                [
                    {id: "yoy", data: convert(data['no_warranty']['yoy'])},
                    // {id: "Airbus", data: convert(data['orders']['Airbus smooth'])},
                ]
            }
        />

        <GraphComponent
            title={"Out of warranty cycles - total (7 day mean)"}
            data={
                [
                    {id: "total", data: convert(data['no_warranty']['total'])},
                    // {id: "Airbus", data: convert(data['orders']['Airbus smooth'])},
                ]
            }
        />

        <GraphComponent
            title={"Out of warranty cycles - percentage of 2019 (7 day mean)"}
            data={
                [
                    {id: "percentage of 2019", data: convert(data['no_warranty']['p19'])},
                    // {id: "Airbus", data: convert(data['orders']['Airbus smooth'])},
                ]
            }
        />

        <GraphComponent
            title={"In warranty planes market share by cycle count"}
            data={
                [
                    {id: "Percentage of market", data: convert(data['in_warranty']['market_share_by_cycle']['total'])},
                    {id: "Trend (365 days mean)", data: convert(data['in_warranty']['market_share_by_cycle']['trend'])},
                    // {id: "Airbus", data: convert(data['orders']['Airbus smooth'])},
                ]
            }
            // layers={[
            //     // includes all default layers
            //     "grid",
            //     "markers",
            //     "axes",
            //     "areas",
            //     "crosshair",
            //     "line",
            //     "slices",
            //     "points",
            //     "mesh",
            //     "legends",
            //     DashedSolidLine // add the custome layer here
            // ]}
        />

        <GraphComponent
            title={"Mean fleet age weighted by cycle count"}
            data={
                [
                    {id: "Total", data: convert(data['mean_fleet_age']['total'])},
                    {id: "Trend (12 month)", data: convert(data['mean_fleet_age']['trend'])},
                ]
            }
        />

        <GraphComponent
            title={`Fleet age x cycle distribution - ${data['month_dist']['date']}`}
            data={
                [
                    {id: "Total", data: convert(data['month_dist']['dist'])},
                ]
            }
        />

    </Container>
}


export default FleetAgePage
